import {StatusProvider} from "./status_context";

import ComponentContainer from "../Container/component_container";

const PageContainer = ({ title, game_id, addons }) => {

    return (
        <StatusProvider>
            <ComponentContainer key={`${title}-${game_id}`} title={title} game_id={game_id} addons={addons}/>
        </StatusProvider>
    );
};

export default PageContainer;

