import {useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import {usePageContext} from "../Page/page_context";
import {useStatusContext} from "../Page/status_context";

function stringToDate(date) {

    if (date === 'Forever' || date === null || date === '' || date === undefined) {
        return ([null, null]);
    } else {
        let normalDate = date.split('T')[0];
        let dateParts = normalDate.split('-');
        let start = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        if (dateParts.length === 6) {
            let end = new Date(dateParts[3], dateParts[4] - 1, dateParts[5]);
            return ([start, end])
        } else return ([start, start])
    }
}

const LabelField = ({text, game_url}) => {
    const { setStatus } = useStatusContext();
    const {updateCurrentLabel} = usePageContext();
    const {WakeUp} = usePageContext();

    const deleteLabel = async () => {
        setStatus({text: "Deleting label...", color: 2});
        const deleteUrl = game_url + `&remove=${text}`;
        try {
            await fetch(deleteUrl, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Connection': 'close',
                }
            });
            updateCurrentLabel("Default");
            setStatus({text: "Label deleted successfully", color: 0});
            WakeUp();
        } catch (error) {
            setStatus({text: "Error deleting label", color: 1});
            console.error('Error deleting from API:', error);
        }
    }
    return (
        <div>
            {text !== 'Default' ? <button onClick={deleteLabel} className={"table_button"}>&times;</button> : <button className={"table_button"}>#</button> }
            {text}
        </div>
    )
}


const PeriodField = ({date, label, game_url}) => {
    const {setStatus} = useStatusContext();
    const [dates, setDates] = useState(stringToDate(date));
    const {WakeUp} = usePageContext();

    const handleDateRangeChange = async (update) => {
        setDates(update);
        setStatus({text: "Editing date...", color: 2});
        if (update[0] != null && update[1] != null) {

            const startDate = new Date(update[0]);
            startDate.setDate(startDate.getDate() + 1);
            const endDate = new Date(update[1]);
            endDate.setDate(endDate.getDate() + 1);

            const response = await fetch(`${game_url}&read=${label}`,{
                credentials: 'include',
                method: "GET",
            });
            const data = await response.json();
            data['date'] = {'start': startDate.toISOString().split('T')[0], 'end': endDate.toISOString().split('T')[0]};
            const keyvalue = JSON.stringify(data);

            const addUrl = game_url + `&add=${label}&keyvalue=${encodeURIComponent(keyvalue)}`;

            try {
                await fetch(addUrl, {
                    method: 'GET', // or 'POST' if your API supports it
                    credentials: 'include',
                });
                WakeUp();
                setStatus({text: "Date changed successfully", color: 0});
            } catch (error) {
                setStatus({text: "Error changing date", color: 1});
                console.error('Error adding label:', error);
            }
        } else {
        }
    };

    return (
        <div>
            {dates[0] === null && dates[1] === null ?
                <div>Forever</div>
                :
                <div>
                    <DatePicker
                        className={"table_datepicker_input"}
                        selectsRange
                        startDate={dates[0]}
                        endDate={dates[1]}
                        onChange={handleDateRangeChange}
                        zIndexOffset={1000}
                    />
                </div>
            }
        </div>
    );
};

export {LabelField, PeriodField};