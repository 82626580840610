import React, { createContext, useContext, useState } from 'react';

const StatisticsContext = createContext(undefined);

export const useStatisticsContext = () => useContext(StatisticsContext);

const activeStatistics = ["Total Unique Users who entered the game"]

export const StatisticsContextProvider = ({ children }) => {

    const [selectedStatistics, setSelectedStatistics] = useState(activeStatistics);
    const [generalStatistics, setGeneralStatistics] = useState(null);
    const [generalStatsLoading, setGeneralStatsLoading] = useState(false);



    return (
        <StatisticsContext.Provider value={{ selectedStatistics, setSelectedStatistics, generalStatistics, setGeneralStatistics, generalStatsLoading, setGeneralStatsLoading }}>
            {children}
        </StatisticsContext.Provider>
    );
};

export default StatisticsContextProvider