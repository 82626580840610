import React, {useEffect, useRef, useState} from 'react';
import {useStatusContext} from "../../Page/status_context";
import {postChallenge} from "../../../Utility/api";
import {Button, Input} from "@mui/material";
import {useAlertContext} from "../../Page/alert_context";

const TriviaGenerator = ({game_id}) => {
    const {setStatus} = useStatusContext()
    const { showAlert } = useAlertContext();
    const [name, setName] = useState("");
    const [mediaType, setMediaType] = useState(null);
    const [mediaFile, setMediaFile] = useState(null);
    const [mediaSrc, setMediaSrc] = useState(null);
    const [triviaCount, setTriviaCount] = useState(0);
    const [triviaQuestions, setTriviaQuestions] = useState([]);
    const [triviaAnswers, setTriviaAnswers] = useState([]);

    const handleFileChange = (e) => {
        setStatus({text: "File added", color: 2});
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            setMediaFile(file);
            const fileType = file.type.split('/')[0];
            const fileSubType = file.type.split('/')[1];

            setMediaType(fileSubType === 'gif' ? 'gif' : fileType);

            const reader = new FileReader();
            reader.onload = (event) => {
                setMediaSrc(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleTriviaCountChange = (delta) => {
        const newCount = Math.max(0, triviaCount + delta);
        setTriviaCount(newCount);

        const updatedTrivia = [...triviaQuestions];
        if (newCount > triviaQuestions.length) {
            for (let i = triviaQuestions.length; i < newCount; i++) {
                updatedTrivia.push("");
            }
        } else {
            updatedTrivia.length = newCount;
        }
        setTriviaQuestions(updatedTrivia);

        const updatedAnswers = [...triviaAnswers];
        if (newCount > triviaAnswers.length) {
            for (let i = triviaAnswers.length; i < newCount; i++) {
                updatedAnswers.push("");
            }
        } else {
            updatedAnswers.length = newCount;
        }
        setTriviaAnswers(updatedAnswers);
    };

    const handleTriviaChange = (index, value) => {
        const updatedTrivia = [...triviaQuestions];
        updatedTrivia[index] = value;
        setTriviaQuestions(updatedTrivia);
    };

    const handleAnswerChange = (index, value) => {
        const updatedAnswers = [...triviaAnswers];
        updatedAnswers[index] = value;
        setTriviaAnswers(updatedAnswers);
    }

    const renderMedia = () => {
        switch (mediaType) {
            case 'image':
            case 'gif':
                return <img src={mediaSrc} alt="Uploaded Media" style={{maxWidth: '400px', maxHeight: '400px'}}/>;
            case 'video':
                return <video controls src={mediaSrc} style={{maxWidth: '255px', maxHeight: '255px'}}/>;
            case 'audio':
                return <audio controls src={mediaSrc}/>;
            default:
                return null;
        }
    };


    const uploadToAPI = async () => {
        setStatus({text: "Uploading to API", color: 2});
        const reader = new FileReader();
        reader.readAsDataURL(mediaFile);
        reader.onload = async () => {
            const base64Content = reader.result.split(',')[1]; // Remove the content type prefix

            const payload = {
                file: {
                    filename: name, content: base64Content, contentType: mediaFile.type
                }
            };

            // console.log(payload);

            await fetch(process.env.REACT_APP_API_URL_REQUEST + "?gameid=8", {
                method: 'POST', headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify(payload)
            }).then(response => response.json())
                .then(data => console.log(data))
                .catch(error => console.error('Error:', error));
            setStatus({text: "Uploaded to API", color: 0});

        };
        reader.onerror = error => console.log(error);
        if (game_id === null) return;
        await postChallenge(game_id, 1, name, "trivia", triviaQuestions.toString());
        await postChallenge(game_id, 2, name, "answer", triviaAnswers.toString());
        showAlert(<span>Successful trivia upload!</span>, 'success');
    };

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                <Input
                    id="file-upload-trivia"
                    type="file"
                    accept="image/*,video/*,audio/*"
                    onChange={handleFileChange}
                />
            </div>

            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                {renderMedia()}
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                <button className={"button square-border"} onClick={() => handleTriviaCountChange(-1)}>-</button>
                <input className={"input-button-match square-border"} type="number" value={triviaCount} readOnly
                       style={{textAlign: 'center', width: '50px'}}/>
                <button className={"button square-border"} onClick={() => handleTriviaCountChange(1)}>+</button>
            </div>
            <div className={"big-input-list"}>
                {triviaQuestions.map((question, index) => (
                    <div style={{display: 'flex', justifyContent: 'space-between', width: "100%"}}>
        <textarea
            key={index}
            value={question}
            onChange={(e) => handleTriviaChange(index, e.target.value)}
            placeholder={`Trivia Question ${index + 1}`}
        />
                        <textarea
                            key={index}
                            value={triviaAnswers[index]}
                            onChange={(e) => handleAnswerChange(index, e.target.value)}
                            placeholder={`Trivia Answer ${index + 1}`}
                        />
                    </div>))}
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                <input className={"input-2"} type="text" value={name} onChange={(e) => setName(e.target.value)}
                       placeholder={"Challenge Name..."}/>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                <button className={"button"} onClick={uploadToAPI}>Upload to API</button>
            </div>
            <br/>
        </div>);
}

export default TriviaGenerator;
