import React, { useState } from 'react';
import '../../Components/Page/page_header.css';
import { useSidebarContext } from "../../Components/Sidebar/sidebar_context";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Denda from "./Logos/Denda.png";
import Azerion from "./Logos/Azerion.png";
import CNN from "./Logos/CNN.png";
import DPG from "./Logos/dpg.png"
import { UserRoundCog, LogOut, User, Settings } from 'lucide-react';


export const Profile = () => {
    const {
        setAuthenticated,
        setSidebarOpen,
    } = useSidebarContext();

    const userIcons = {
        "Denda": Denda,
        "CNN": CNN,
        "DPG": DPG,
        "Azerion": Azerion
        // Add other company names and corresponding imported icons here
    };

    const [currentPage, setCurrentPage] = useState(null);

    // Determine if the menu should be open based on whether currentPage is set
    const open = Boolean(currentPage);

    // Set the element that was clicked as the anchor for the menu
    const handleClick = (event) => {
        setCurrentPage(event.currentTarget);
    };

    // Close the menu by setting currentPage to null
    const handleClose = () => {
        setCurrentPage(null);
    };

     function handlePage(page){
         window.location.href = `/${page}`;
    }

    const handleLogout = async () => {
        const url = process.env.REACT_APP_API_URL_AUTH + "/logout";
        const requestOptions = {
            method: 'POST',
            credentials: 'include',  // This includes cookies in the request
            headers: {
                'Content-Type': 'application/json'
            }
        };

        console.log("Sending fetch request to:", url);
        console.log("Request options:", requestOptions);

        try {
            const response = await fetch(url, requestOptions);
            const data = await response.json();
            console.log("Response received:", data);
            setAuthenticated(false);
            setSidebarOpen(false);
        } catch (error) {
            console.error('Error:', error);
            setAuthenticated(false);
            setSidebarOpen(false);
        }
    };

    // Get the current brand value from the app context
    const { brand } = useSidebarContext();

    // Function to fetch the brand logo based on the brand name
    const fetchBrand = (brandName) => {
    if (!brandName) return <User/>;
    return `${process.env.PUBLIC_URL}/Logos/${brandName}.png`;
    };
    console.log(brand);
    console.log(fetchBrand(brand));
    return (
        <div className="user-profile"> {/* Main container for the profile section */}
                <IconButton onClick={handleClick}> {/* Set the click handler to open the menu */}
                    <img className="profile-image" src={userIcons[brand]} alt="Brand Logo" /> {/* Display the brand logo as the profile image */}
                </IconButton>
                <Menu
                    anchorEl={currentPage} // Set the element to which the menu should be anchored
                    open={open} // Whether the menu is open
                    onClose={handleClose} // Close handler
                    onClick={handleClose}
                >
                    <MenuItem onClick={handleClose}>
                        <User className="dropdown-icon" /> Account
                    </MenuItem>
                    <MenuItem onClick={() => handlePage(`Administration`)}>
                        <UserRoundCog className="dropdown-icon" /> Administration
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <Settings className="dropdown-icon" /> Settings
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                        <LogOut className="dropdown-icon" /> Logout
                    </MenuItem>
                </Menu>
        </div>
    );
};

export default Profile;
