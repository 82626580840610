/*component_container.js is a component that renders the appropriate component based on the title passed to it.
 It uses the componentMapping object to map the title to the appropriate component.
 It also passes the game_id and addons to the component.
 If the component is not found, it logs an error to the console and returns null.*/

import {useStatusContext} from "../Page/status_context";
import ContainerHeader from "../Page/container_header";
import LabelManager from "../Main/label_manager";
import ChallengeViewer from "../Main/challenge_viewer";
import ChallengeModifier from "../Main/Challenge Modifier/challenge_modifier";
import TriviaGenerator from "../Unique/Trivia/trivia_generator";
import NonogramGenerator from "../Unique/Nonogram/Generator From Image/nonogram_generator";
import ComponentManager from "../Administration/component_manager";
import PageManager from "../Administration/page_manager";
import UserManager from "../Administration/user_manager";
import GraphWithOptions from "../Statistics/graph_with_options";
import StatisticsData from "../Statistics/statistics_data";
import FeedbackTable from "../Main/feedback_table";
import {NonogramViewer} from "../Unique/Nonogram/Generator From Image/nonogram_viewer";
import FileUploader from "../Main/File Uploader/file_uploader";
import StructurePreview from "../Unique/Tiles/structure_preview";
import TilesetPreview from "../Unique/Tiles/tileset_preview";
import LoadingScreenDenda from "../Unique/Animation/loading_screen";
import Creator from "../Administration/creator";
import FourGraph from "../Statistics/four_graph";
import ExistingNonogramViewer from "../Unique/Nonogram/Generator From Image/existing_nonogram_viewer";
import CompletionPercentagePieChart from "../Statistics/CompletionPercentagePieChart";
import TotalTimeGraph from "../Statistics/TotalTimeGraph";
import {CrosswordGenerator} from "../Unique/Crossword/crossword_generator";
import ExistingSudotetrisViewer from "../Unique/Sudotetris/existing_sudotetris_viewer";


export const componentMapping = {
    "Label Table": LabelManager,
    "Challenge Viewer": ChallengeViewer,
    "Challenge Editor": ChallengeModifier,
    "Trivia Generator": TriviaGenerator,
    "Nonogram Generator": NonogramGenerator,
    "Component Manager": ComponentManager,
    "Page Manager": PageManager,
    "User Manager": UserManager,
    "Graph": GraphWithOptions,
    "Numbers": StatisticsData,
    "Feedback": FeedbackTable,
    "Nonogram Viewer": NonogramViewer,
    "File Uploader": FileUploader,
    "Structure Preview": StructurePreview,
    "Tile-set Preview": TilesetPreview,
    "Existing Nonograms": ExistingNonogramViewer,
    "Creator": Creator,
    "In-depth Statistics": FourGraph,
    "Completion Pie Chart": CompletionPercentagePieChart,
    "Total Time Graph": TotalTimeGraph,
    "Crossword Viewer": CrosswordGenerator,
    "Sudotetris Viewer": ExistingSudotetrisViewer
};

const ComponentContainer = ({title, game_id, addons}) => {
    const {loading} = useStatusContext();
    const ComponentToRender = componentMapping[title];

    if (!ComponentToRender) {
        console.error(`Component not found for title: ${title}`);
        return null;
    }

    return (
        <div className={"bubble-container"}>
            <ContainerHeader title={title}/>
            <header/>
            <div className={"component-container"}>
                {loading ? (
                    <LoadingScreenDenda key={`${title}-${game_id}`} text="Loading"/>
                ) : (
                    <ComponentToRender key={`${title}-${game_id}`} game_id={game_id} addons={addons}/>
                )}
            </div>
        </div>
    );
}

export default ComponentContainer;