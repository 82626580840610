import { useNonogramContext } from "./nonogram_context"; // Import context for Nonogram data
import { useEffect, useState } from "react"; // Import React hooks

/**
 * Function to generate Nonogram clues from the puzzle data.
 * @param {Array} array - The flattened array representing the nonogram grid (1D array).
 * @param {number} width - The width of the nonogram grid.
 * @param {number} height - The height of the nonogram grid.
 * @returns {Object} - An object containing the row and column clues.
 */
function getNonogramClues(array, width, height) {
    const rowClues = [];
    const columnClues = [];

    // Generate clues for each row
    for (let i = 0; i < height; i++) {
        // Extract the current row from the array
        const row = array.slice(i * width, (i + 1) * width);
        const clues = [];
        let counter = 0;
        for (const cell of row) {
            if (cell === 1) {
                // If cell is filled, increment the counter
                counter++;
            } else if (counter !== 0) {
                // If cell is empty and counter is not zero, push the counter to clues
                clues.push(counter);
                counter = 0;
            }
        }
        // After the row ends, check if there is a remaining counter
        if (counter !== 0) clues.push(counter);
        // Add the clues for this row to the rowClues array
        rowClues.push(clues);
    }

    // Generate clues for each column
    for (let i = 0; i < width; i++) {
        const column = [];
        // Build the current column by extracting elements from the array
        for (let j = 0; j < height; j++) {
            column.push(array[j * width + i]);
        }
        const clues = [];
        let counter = 0;
        for (const cell of column) {
            if (cell === 1) {
                // If cell is filled, increment the counter
                counter++;
            } else if (counter !== 0) {
                // If cell is empty and counter is not zero, push the counter to clues
                clues.push(counter);
                counter = 0;
            }
        }
        // After the column ends, check if there is a remaining counter
        if (counter !== 0) clues.push(counter);
        // Add the clues for this column to the columnClues array
        columnClues.push(clues);
    }

    // Return the row and column clues
    return { rowClues, columnClues };
}

/**
 * Component to display the Nonogram puzzle with clues.
 */
export const NonogramViewer = () => {
    // Extract necessary data from Nonogram context
    const { bwValues, pixelArtSize, setIsValid } = useNonogramContext();
    const [rows, setRows] = useState([]);     // State to store row clues
    const [columns, setColumns] = useState([]); // State to store column clues

    // Effect to generate clues and validate the puzzle when bwValues change
    useEffect(() => {
        const temp = getNonogramClues(bwValues, pixelArtSize, pixelArtSize);
        setRows(temp.rowClues);       // Update row clues
        setColumns(temp.columnClues); // Update column clues

        // Check if any row or column has more than 5 clues
        const isOverFive = temp.rowClues.some(clue => clue.length > 5) || temp.columnClues.some(clue => clue.length > 5);
        setIsValid(!isOverFive); // Set validity based on clue length
    }, [bwValues]);

    // Function to generate grid style based on pixelArtSize
    const generateGridStyle = () => {
        return {
            display: 'grid',
            gridTemplateColumns: `repeat(${pixelArtSize}, 1fr)`, // Create grid columns
            gridGap: '1px', // Gap between grid cells
        };
    };

    // Function to render the nonogram grid
    const renderNonogramGrid = () => {
        if (!bwValues) return null; // Return null if bwValues is not available
        return bwValues.map((value, index) => {
            const rowIndex = Math.floor(index / pixelArtSize);     // Calculate row index
            const columnIndex = index % pixelArtSize;              // Calculate column index
            const isRowRed = rows[rowIndex] && rows[rowIndex].length > 5;      // Check if row has too many clues
            const isColumnRed = columns[columnIndex] && columns[columnIndex].length > 5; // Check if column has too many clues
            const isRed = isRowRed || isColumnRed; // Determine if cell should be highlighted in red
            return (
                <div
                    key={`cell-${index}`}
                    style={{
                        width: '20px',
                        height: '20px',
                        backgroundColor: value === 1 ? (isRed ? 'red' : 'black') : 'white', // Set cell color
                        border: '1px solid #ddd' // Cell border
                    }}
                />
            );
        });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
            <div className="clue-wrapper" style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gridGap: '10px' }}>
                {/* Row clues */}
                <div className="row-clues">
                    {/* Placeholder to align column clues */}
                    <div style={{ width: '50px', height: '101px' }} />
                    {/* Render row clues */}
                    {rows.map((rowClue, index) => (
                        <div
                            key={`row-clue-${index}`}
                            style={{
                                textAlign: 'right',
                                padding: '1.5px',
                                width: '70px',
                                height: '20px',
                                alignSelf: 'center',
                                justifySelf: 'center',
                                color: rowClue.length > 5 ? 'red' : 'black' // Highlight clue if too long
                            }}
                        >
                            {rowClue.join(' ')} {/* Display clues separated by spaces */}
                        </div>
                    ))}
                </div>
                <div>
                    {/* Column clues */}
                    <div
                        className="column-clues"
                        style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}
                    >
                        {/* Render column clues */}
                        {columns.map((columnClue, index) => (
                            <div
                                key={`column-clue-${index}`}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginRight: '3px',
                                    width: '20px',
                                    height: '90px',
                                    color: columnClue.length > 5 ? 'red' : 'black' // Highlight clue if too long
                                }}
                            >
                                {columnClue.map((clue, clueIndex) => (
                                    <div key={`column-clue-${index}-${clueIndex}`} style={{ marginBottom: '1px' }}>
                                        {clue} {/* Display individual clue number */}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    {/* Nonogram grid */}
                    <div className="nonogram-grid" style={generateGridStyle()}>
                        {renderNonogramGrid()} {/* Render the grid cells */}
                    </div>
                </div>
                <br />
            </div>
        </div>
    );
};
