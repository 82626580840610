import {useStatusContext} from "./status_context";
import {useEffect, useState} from "react";
import "./default_page_style.css"

const ContainerHeader = ({title}) => {
    const {status} = useStatusContext();
    const [item, setItem] = useState(null);
    const [fade, setFade] = useState(false);

    const [statusBar, setStatusBar] = useState('5px solid #007bff');

    const borderStyles = {
        0: '5px solid #007bff', // blue
        1: '5px solid #ff6b6c', // red
        2: '5px solid #ffc65f', // orange
        3: '5px solid #f9f871' // yellow
    };

    useEffect(() => {
        if (status === undefined) {
            return;
        }
        setStatusBar(borderStyles[status.color]);
        setItem({text: status.text});
        setFade(true);
    }, [status]);

    useEffect(() => {
        if (fade) {
            const timer = setTimeout(() => {
                setFade(false);
            }, 3000); // Change this value to adjust the display time

            return () => clearTimeout(timer); // This will clear the timer when the component unmounts
        }
    }, [fade]);

    return (
        <div className="component_header"
             style={{
                 borderRight: statusBar,
                 // borderLeft: "5px solid #C5C4C2", //leave if you want a border on the left
                 borderTopLeftRadius: '10px',
                 borderTopRightRadius: '10px',
             }}>
            {/*<hr className={"component_title"}/>*/}
            <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between'}}>
                <h3 className={"component_title"}>{title}</h3>
                {item && ( // Check if item is not null before rendering
                    <div style={{
                        paddingRight: '5px',
                        wordWrap: 'break-word',
                        opacity: fade ? 1 : 0,
                        transition: 'opacity 0.3s ease-in-out'
                    }}>{item.text}</div>
                )}
            </div>
            {/*<hr className={"component_title"}/>*/}
        </div>
    )
}

export default ContainerHeader;