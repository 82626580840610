import React, { createContext, useContext, useState } from 'react';

const PageContext = createContext(undefined);

export const usePageContext = () => useContext(PageContext);

export const PageContextProvider = ({ children }) => {
    const [labelAdded, setLabelAdded] = useState(false);
    const [wakeUp, setWakeup] = useState(false);

    const updateLabels = () => {
        setLabelAdded(prev => !prev); // Toggle to trigger update
    };

    const [currentLabel, setCurrentLabel] = useState('Default');

    const updateCurrentLabel = (label) => {
        setCurrentLabel(label);
    };

    const WakeUp = () => {
        setWakeup(!wakeUp);
        return wakeUp;
    }

    return (
        <PageContext.Provider value={{ labelAdded, updateLabels, currentLabel, updateCurrentLabel, WakeUp, wakeUp }}>
            {children}
        </PageContext.Provider>
    );
};

export default PageContextProvider