// sidebar.js

import React, {useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import "./sidebar.css";
import LinkDrop from "./link_drop";
import {useSidebarContext} from "./sidebar_context";
import logo from "../Assets/Denda_New3.png";
import SudokuIcon from "../Assets/sudoku.png"
import CombinationIcon from "../Assets/combination.png"
import NonoIcon from "../Assets/nonogram.png"
import SolitareIcon from "../Assets/solitare.png"
import SudoblockIcon from "../Assets/sudoblock.png"
import SudotetroidIcon from "../Assets/sudotetroid.png"
import { House, User, Settings } from 'lucide-react';

const pageIcons = {
    "Settings": <Settings />,
    "User": <User />,
    "Combinations": CombinationIcon,
    "Nonogram": NonoIcon,
    "Solitare": SolitareIcon,
    "Sudoku": SudokuIcon,
    "Sudoblock": SudoblockIcon,
    "Sudotetroid": SudotetroidIcon,

    // Add other page names and corresponding icons here
};


const Sidebar = () => {
    // Destructure values and setters from SidebarContext
    const {
        isSidebarOpen,
        setSidebarOpen,
        setBrand,
        setAccess,
        setDates,
        setAuthenticated,
        authenticated,
        structuredPages,
        setComponents,
        setPages,
        setUser,
        setUserChildren,
        setGames,
        pages,
        components,
        setStructuredPages,
        user,
    } = useSidebarContext();

    // Get the current date formatted as YYYY-MM-DD
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // padStart ensures the month is always 2 digits
    const day = String(currentDate.getDate()).padStart(2, '0'); // padStart ensures the day is always 2 digits
    const formattedDate = `${year}-${month}-${day}`;

    // Check user authentication status on component mount
    useEffect(() => {
        const controller = new AbortController();
        const {signal} = controller; // Signal to abort fetch if needed
        const apiUrl = process.env.REACT_APP_API_URL_AUTH + '/login/session';
        console.log('API URL:', apiUrl);

        const fetchAuthStatus = async () => {
            try {
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    credentials: 'include', // Include cookies in the request
                    signal
                });
                console.log('Response Status:', response.status);
                console.log('Response OK:', response.ok);

                if (!response.ok) { // Checks if the status code is outside the 200-299 range
                    throw new Error('HTTP status ' + response.status);
                }
                const data = await response.json();
                console.log('Response Data:', data);

                if (data['isValid']) {
                    setAuthenticated(data['isValid']); // Update authentication status
                    setUser(data['username']); // Set the username
                    console.log('User authenticated:', data['isValid']);
                    console.log('Username:', data['username']);
                } else {
                    console.log('User not authenticated');
                }
            } catch (error) {
                console.error('Error:', error);
                setAuthenticated(false);
            }
        };

        fetchAuthStatus();

        return () => {
            console.log('Aborting fetch request');
            controller.abort(); // Abort fetch on component unmount
        };
    }, [setAuthenticated, setUser]);

    // Fetch user-specific data once authenticated
    useEffect(() => {
        if (!authenticated) return; // Exit if not authenticated
        console.log(authenticated);

        const fetchData = async () => {
            try {
                const getData = {
                    "username": user,
                    "method": "getUserPageAccess"
                };

                const response = await fetch(process.env.REACT_APP_API_URL_ADMINISTRATION, {
                    method: 'POST',
                    body: JSON.stringify(getData),
                    credentials: 'include'
                });
                if (!response.ok) { // Ensure response is valid before attempting to read it
                    throw new Error('Failed to fetch user-specific data');
                }
                const data = await response.json();

                // Update various state variables with fetched data
                setBrand(data['brand'] || []);
                setAccess(data['access'] || []);
                setDates([data['start_date'], formattedDate]);
                setComponents(data['components'] || []);
                setPages(data['pages'] || []);
                setUser(data['user_info'] || null);
                setUserChildren(data['children'] || []);
                setGames(data['games'] || []);
                console.log("raw login data: ", data);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [authenticated, user, setBrand, setAccess, setDates, setComponents, setPages, setUser, setUserChildren, setGames, formattedDate]);
    // Build structuredPages object based on pages and components
    useEffect(() => {
        let structuredPages = {};
        if (Array.isArray(pages)) {
            pages.forEach(page => {
                // Check if it's a subpage
                if (page && page.parent_id) {
                    // Ensure the parent page exists in the structure
                    if (!structuredPages[page.parent_id]) {
                        structuredPages[page.parent_id] = {subpages: {}};
                    } else {
                        structuredPages[page.parent_id].subpages = structuredPages[page.parent_id].subpages || {};
                    }
                    // Add subpage to the parent page's subpages
                    if (!structuredPages[page.parent_id].subpages[page.page_id]) {
                        structuredPages[page.parent_id].subpages[page.page_id] = {};
                    }
                } else if (page && page.page_id) {
                    // If it's not a subpage, process it as a main page
                    if (!structuredPages[page.page_id]) {
                        structuredPages[page.page_id] = {};

                    }
                }
            });
        }

        console.log(structuredPages);

        // Process components and assign them to their respective pages or subpages
        if (Array.isArray(components)) {
            components.forEach(component => {
                if (component) {
                    let targetPage = structuredPages[component.page_id];
                    let targetSubpage;
                    if (structuredPages[component.parent_id] && structuredPages[component.parent_id].subpages) {
                        targetSubpage = structuredPages[component.parent_id].subpages[component.page_id];
                    }

                    if (targetPage) {
                        // Add component to the main page
                        targetPage.containers = targetPage.containers || [];
                        targetPage.containers.push({
                            title: component.component_id,
                            tier: component.access_tier
                        });
                    } else if (targetSubpage) {
                        // Add component to the subpage
                        targetSubpage.containers = targetSubpage.containers || [];
                        targetSubpage.containers.push({
                            title: component.component_id,
                            tier: component.access_tier
                        });
                    } else {
                        console.error(`No target page or subpage found for component: ${component.component_id}`);
                    }
                }
            });
        }

        // Update the structuredPages state
        setStructuredPages(structuredPages);
    }, [pages, components, setStructuredPages]);

    // Function to toggle sidebar open/closed state
    const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

    const handleLogout = () => {
        // setAuthenticated(false);
        const url = process.env.REACT_APP_API_URL_AUTH + "/logout";
        const requestOptions = {
            method: 'POST',
            credentials: 'include'
        }
        // Logging the request options to see what will be sent
        console.log("Sending fetch request to:", url);
        console.log("Request options:", requestOptions);
    };

        // Render sidebar only if user is authenticated
        return (
            authenticated ? (
                    <>
                        <div className={`sidebar`}>
                            <div className={`sidebar-top`}>
                                <div className="logo-container">
                                    <img src={logo} alt="Logo" className={`d-md-block sidebar-logo `}/>
                                </div>
                                {/*<IconButton className="menu-icon" onClick={toggleSidebar}>*/}
                                {/*    {isSidebarOpen ? (*/}
                                {/*        <LeftArrow className="menu-icon" />*/}
                                {/*    ):*/}
                                {/*        ( <RightArrow className="menu-icon" />)*/}
                                {/*    }*/}
                                {/*</IconButton>*/}
                            </div>
                            <nav>
                                {/* Home link */}
                                <NavLink
                                    className={({isActive}) => `sidebar-item  ${isActive ? 'active-link' : 'link'}`}
                                    onClick={() => {
                                        if (window.innerWidth <= 768) toggleSidebar();
                                    }}
                                    to="/"
                                >
                                    <House className="sidebar-icon"/>

                                </NavLink>

                                {/* Dynamically render pages from structuredPages */}
                                {authenticated && Object.keys(structuredPages).map((pageKey) => {

                                    if (pageKey.toLowerCase() === 'administration') {
                                        return null; // Skip this page
                                    }

                                    const page = structuredPages[pageKey];
                                    return (
                                        <LinkDrop
                                            Icon={pageIcons[pageKey]}
                                            key={pageKey}
                                            page={page}
                                            base={pageKey}
                                            onNavigate={() => {
                                                if (window.innerWidth <= 768) toggleSidebar();
                                            }}
                                        />
                                    );
                                })}

                            </nav>
                        </div>
                    </>
                ) :
                null // If not authenticated, close sidebar and render nothing
        );
};
export default Sidebar;