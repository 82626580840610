import React from 'react';
import './loading.css';
import {useEffect, useState} from "react";
// import logo from '../../Assets/Denda_New3.png';

const LoadingScreenDenda = ({text}) => {
    const [dots, setDots] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prev = '..') => prev.length >= 3 ? '' : prev + '.');
        }, 500);// change this value to make dots go faster or slower
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="loading-screen">
            {/*<img src={logo} alt="Logo" className="loading-icon"/>*/}
            {text && <div className="loading-text">{text}{dots}</div>}
        </div>
    );
}

export default LoadingScreenDenda;
// Path: loading_screen.css