import React, {createContext, useState, useMemo} from 'react';
import {createMuiTheme, ThemeProvider as MuiThemeProvider} from '@mui/material/styles';

export const ThemeContext = createContext();

export const ThemeProvider = ({children}) => {
    const [currentTheme, setCurrentTheme] = useState({
        primaryColor: '#562771',
        secondaryColor: '#962161'
    }); // This can be your default theme

    const setTheme = (newTheme) => {
        setCurrentTheme(newTheme);
    };

    return (
        <ThemeContext.Provider value={{theme: currentTheme, setTheme}}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;
