// Code: Sidebar context for the sidebar component

import React, {createContext, useState, useContext, useEffect} from 'react';

const SidebarContext = createContext(undefined);

export const useSidebarContext = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const [items, setItems] = useState([]);
    const [authenticated, setAuthenticated] = useState(false);
    const [brand, setBrand] = useState("");
    const [access, setAccess] = useState([]);
    const [dates, setDates] = useState(["2023-11-01", "3000-01-01"]);
    const [game_id, setGame_id] = useState(null);
    const [components, setComponents] = useState([]);
    const [pages, setPages] = useState([]);
    const [structuredPages, setStructuredPages] = useState({}); // {page: {games: []}
    const [user, setUser] = useState([0,'']);
    const [userChildren, setUserChildren] = useState([]);
    const [games, setGames] = useState([]);
    const [game_index, setGame_index] = useState(0);
    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

    const toggleMobileSidebar = () => setSidebarOpen(!isSidebarOpen);
    const addLog = (title, text, color) => {
        setItems(prevItems => [
            { id: Date.now(), title: title, text: text, color: color },
            ...prevItems,
        ]);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setSidebarOpen(true);
            } else {
                setSidebarOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call initially to set the correct state

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <SidebarContext.Provider value={{
            isSidebarOpen, setSidebarOpen,
            items,
            addLog,
            authenticated, setAuthenticated,
            brand, setBrand,
            access, setAccess,
            dates, setDates,
            game_id, setGame_id,
            components, setComponents,
            pages, setPages,
            structuredPages, setStructuredPages,
            user, setUser,
            userChildren, setUserChildren,
            games, setGames,
            game_index, setGame_index,
            isMobileSidebarOpen, toggleMobileSidebar,
        }}>
            {children}
        </SidebarContext.Provider>
    );
};
