import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <DevSupport ComponentPreviews={ComponentPreviews}
                    useInitialHook={useInitial}
        >
            <App/>
        </DevSupport>
    </React.StrictMode>
);


// TODO: Remove all redundant code
// TODO: Change README.md to a good one
// TODO: Add a LICENSE file
// TODO: Add memory for login information
// TODO: Add a Trivia Maker and PReview component
// TODO: Add a Combinations Uploader and Preview
// TODO: Add error handling to all forms of input (Like in Combinations Uploader)
// TODO: Implements Stephen's changes
// TODO: Centralize fetching functions
// TODO: Fix the Combinations Viewer