/*feedback_table.js
is a component that displays feedback data in a table format.
It fetches feedback data from the API and displays it in a table.
The table can be sorted by clicking on the column headers.
The table can also be filtered by selecting a field, an operator, and entering a value.
The table is responsive and can be scrolled horizontally.*/


import {useEffect, useState} from "react";
import {useSidebarContext} from "../Sidebar/sidebar_context";
import "../Table/mui-table-style.css"
import "../Page/default_page_style.css"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';


const FeedbackTable = ({game_id}) => {
    const {dates} = useSidebarContext();
    const [data, setData] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [filterField, setFilterField] = useState('date');
    const [filterOperator, setFilterOperator] = useState('contains');
    const [filterValue, setFilterValue] = useState('');

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        const fetchData = async () => {
            if (!game_id) {
                // console.log("Game ID is null, fetch aborted.");
                return;
            }
            try {
                const url = `${process.env.REACT_APP_API_URL_STATISTICS}?method=getFeedback&gameid=${game_id}&date=${dates[0]}_${dates[1]}`;
                const response = await fetch(url, {
                    signal,
                    method: 'GET',
                    credentials: 'include',
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const feedback = await response.json();

                const rows = feedback.brand.map((_, index) => ({
                    date: feedback.date[index],
                    user_ID: feedback.user_ID[index],
                    brand: feedback.brand[index],
                    identifier: feedback.identifier[index],
                    stars: feedback.stars[index],
                    contact: feedback.contact[index],
                    feedback: feedback.feedback[index]
                }));

                setData(rows);
            } catch (error) {
                if (!signal.aborted) {
                    console.error("Error fetching data:", error);
                }
            }
        };

        fetchData();

        // Cleanup function to abort fetch when component unmounts or dependencies change
        return () => {
            // console.log("Aborting fetch for game_id:", game_id);
            controller.abort();
        };
    }, [game_id, dates]);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortArray = (array) => {
        return array.sort((a, b) => {
            if (a[orderBy] === "" || b[orderBy] === "") {
                if (a[orderBy] === "" && b[orderBy] !== "") {
                    return 1; // a is empty, b is not, a should be at the bottom
                } else if (a[orderBy] !== "" && b[orderBy] === "") {
                    return -1; // b is empty, a is not, b should be at the bottom
                }
                return 0; // both are empty, doesn't matter which comes first
            }

            let aValue = a[orderBy];
            let bValue = b[orderBy];

            if (aValue < bValue) {
                return order === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return order === 'asc' ? 1 : -1;
            }
            return 0;
        });
    };

    const sortedData = sortArray([...data]);

    const applyFilter = (row) => {
        const value = row[filterField];
        if (value === "" || value === undefined) {
            return false;
        }
        if (filterOperator === 'contains') {
            return String(value).includes(filterValue);
        } else if (filterOperator === 'equals') {
            return String(value) === String(filterValue);
        } else if (filterOperator === 'startsWith') {
            return String(value).startsWith(filterValue);
        } else if (filterOperator === 'endsWith') {
            return String(value).endsWith(filterValue);
        } else if (filterOperator === 'isEmpty') {
            return value === '' || value === 0;
        } else if (filterOperator === 'isNotEmpty') {
            return value !== '' && value !== 0;
        }
        // Add more operators as needed
    };

    const filteredData = sortedData.filter(applyFilter);

    return (
        <div>
            <div style={{overflowX: "auto"}}>
                <div className="feedback-container">
                    <Select
                        value={filterField}
                        onChange={(e) => setFilterField(e.target.value)}
                        className="dropdown-menu-denda-general"
                        style={{color: 'var(--light)'}}>

                        <MenuItem value="date">Date</MenuItem>
                        <MenuItem value="user_ID">User ID</MenuItem>
                        <MenuItem value="brand">Brand</MenuItem>
                        <MenuItem value="identifier">Identifier</MenuItem>
                        <MenuItem value="stars">Stars</MenuItem>
                        <MenuItem value="contact">Contact</MenuItem>
                        <MenuItem value="feedback">Feedback</MenuItem>
                    </Select>
                    <Select
                        value={filterOperator}
                        onChange={(e) => setFilterOperator(e.target.value)}
                        className="dropdown-menu-denda-general"
                        style={{color: 'var(--light)'}}>
                        <MenuItem value="contains">contains</MenuItem>
                        <MenuItem value="equals">equals</MenuItem>
                        <MenuItem value="startsWith">starts with</MenuItem>
                        <MenuItem value="endsWith">ends with</MenuItem>
                        <MenuItem value="isEmpty">is empty</MenuItem>
                        <MenuItem value="isNotEmpty">is not empty</MenuItem>
                    </Select>
                    <Input
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                        placeholder="Filter value"
                        className="dropdown-menu-denda-general"
                        style={{marginRight: '10px', backgroundColor: 'var(--light)'}}
                    />
                </div>
            </div>
            <TableContainer className="table-container">
                <Table className={"mui-table"}>
                    <TableHead>
                        <TableRow>
                            {['Date', 'User ID', 'Brand', 'Identifier', 'Stars', 'Contact', 'Feedback'].map((headCell) => (
                                <TableCell
                                    key={headCell}
                                    sortDirection={orderBy === headCell.toLowerCase() ? order : false}
                                    className={headCell === "Feedback" ? "" : "min-row"}
                                >
                                    <TableSortLabel className={'fontMono'}
                                                    active={orderBy === headCell.toLowerCase()}
                                                    direction={orderBy === headCell.toLowerCase() ? order : 'asc'}
                                                    onClick={() => {
                                                        handleRequestSort(headCell === "User ID" ? "user_ID" : headCell.toLowerCase());
                                                    }}
                                    >
                                        {headCell}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((row, index) => (
                            <TableRow
                                key={index}
                            >
                                <TableCell /*component="th" scope="row"*/><p className={'fontMono'}>{row.date}</p>
                                </TableCell>
                                <TableCell><p className={'fontMono'}>{row.user_ID}</p></TableCell>
                                <TableCell><p className={'fontMono'}>{row.brand}</p></TableCell>
                                <TableCell><p className={'fontMono'}>{row.identifier}</p></TableCell>
                                <TableCell><p className={'fontMono'}>{row.stars}</p></TableCell>
                                <TableCell><p className={'fontMono'}>{row.contact}</p></TableCell>
                                <TableCell><p className={'fontMono'}>{row.feedback}</p></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default FeedbackTable;
