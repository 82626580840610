import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const TotalTimeGraph = ({ game_id }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            // Mock fetching data logic
            const mockData = {
                dates: ['2021-01-01', '2021-01-02', '2021-01-03'],
                times: [120, 150, 130] // minutes
            };
            setData([
                {
                    x: mockData.dates,
                    y: mockData.times,
                    type: 'scatter',
                    mode: 'lines+markers',
                    marker: {color: 'blue'}
                }
            ]);
        };

        fetchData();
    }, [game_id]);

    return (
        <Plot
            data={data}
            layout={{
                title: 'Total Time for Entire Game',
                xaxis: {title: 'Date'},
                yaxis: {title: 'Time (minutes)'},
                height: 400,
                width: 480
            }}
        />
    );
};

export default TotalTimeGraph;
