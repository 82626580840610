//App.js
// This file is the main entry point for the application.
// It wraps the entire application in the necessary context providers and routers.

import React from 'react';

import Sidebar from "./Components/Sidebar/sidebar";
import {BrowserRouter as Router} from "react-router-dom";
import {SidebarProvider} from "./Components/Sidebar/sidebar_context";
import PageRoutes from "./Pages/routes";
import {ThemeProvider} from "./theme_context";
import {AlertProvider} from "./Components/Page/alert_context";
import AlertComponent from "./Components/Unique/Misc/alert_component";
import 'bootstrap/dist/css/bootstrap.min.css';
import StatisticsContextProvider from "./Components/Statistics/statistics_context";
import AdministrationContextProvider from "./Components/Administration/administration_context";
import {NonogramProvider} from "./Components/Unique/Nonogram/Generator From Image/nonogram_context";
import {TilesProvider} from "./Components/Unique/Tiles/tiles_context";


const App = () => {
    return (
        <AlertProvider>
            <ThemeProvider>
                <SidebarProvider>
                    <NonogramProvider>
                        <TilesProvider>
                    <AdministrationContextProvider>
                        <StatisticsContextProvider>
                            <Router>
                                <Sidebar/>
                                <PageRoutes/>
                                <AlertComponent/>
                            </Router>
                            {/*<div className="footer">*/}
                            {/*    ©Copyright Denda Games 2024*/}
                            {/*</div>*/}
                        </StatisticsContextProvider>
                    </AdministrationContextProvider>
                        </TilesProvider>
                        </NonogramProvider>
                </SidebarProvider>
            </ThemeProvider>
        </AlertProvider>
    );
};

export default App;