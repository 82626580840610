import React, {useEffect, useState} from "react";
import Plot from "react-plotly.js";


const SimpleGraph = ({x, y, type}) => {
    const data= useState({
        type: type,
        mode: 'lines+markers',
        x: x,
        y: y,
        line: {shape: 'spline', smoothing: 0.5},
        marker: {size: 5},
        name: ''
    });
    const today = new Date();
    const oneWeekAgo = new Date(new Date().setDate(today.getDate() - 7));

    const isMobile = window.matchMedia("(max-width: 767px)").matches;

    const [layout] = React.useState({
        xaxis: {
            tickformat: "%b %d %Y", // This will format your ticks to "Nov 5 2023", "Nov 19", etc.
            // tickformat: "%b" // This will format your ticks to "Nov 5 2023", "Nov 19", etc.
            autorange: !isMobile,
            range: isMobile ? [oneWeekAgo.toISOString().split('T')[0], new Date().toISOString().split('T')[0]] : undefined,
        },
        yaxis: {
            autorange: true, // This line allows Plotly to automatically adjust the range
            rangemode: 'tozero' // Ensures that the y-axis starts at 0
        },
        legend: {
            y: -0.1, // Moves the legend below the plot area
            x: 0.5, // Centers the legend
            xanchor: 'center',
            yanchor: 'top',
            orientation: 'h' // Makes the legend horizontal
        },
        autosize: true,
        margin: { l: 60, r: 10, b: 60, t: 30, pad: 4 },
    });

    return (
        <div style={{position: 'relative', width: '100%', height: '100%'}}>
            <Plot
                data={data}
                layout={layout}
                useResizeHandler={true}
                style={{width: '100%', height: '100%'}}
                loading="Loading..."

            />
        </div>
    );
};

export default SimpleGraph;