
export const postChallenge = async (game, variant_id, challenge_name, variant_name, data) => {
    const url = process.env.REACT_APP_API_URL_CHALLENGES;
    const method = "addChallenge"
    const body = {
        "method": method,
        "game": game,
        "variant_id": variant_id,
        "challenge_name": challenge_name,
        "variant_name": variant_name,
        "data": data
    };
    // console.log(body);

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(body)
    };

    try {
        const response = await fetch(url, options);
        return await response.json();
    } catch (error) {
        console.error('There was a problem with the fetch operation: ', error);
    }
};