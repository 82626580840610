import { NavLink, Route, Routes } from "react-router-dom";
import Home from "./Home/home";
import React, { useEffect, useContext } from "react";
import { useSidebarContext } from "../Components/Sidebar/sidebar_context";
import Page from "./Modular/page";

const About = () => <div>Placeholder page</div>; // Placeholder for About page

const PageRoutes = () => {
    // Destructure values from sidebar context
    const {isSidebarOpen, authenticated, structuredPages, setAuthenticated} = useSidebarContext();

    // Effect hook that runs whenever isSidebarOpen changes (currently does nothing)
    useEffect(() => {
    }, [isSidebarOpen]);

    // Handle user logout
    const handleLogout = () => {
        // setAuthenticated(false);
        const url = process.env.REACT_APP_API_URL_AUTH_LOGOUT;
        const requestOptions = {
            method: 'POST',
            credentials: 'include',  // This includes cookies in the request
            headers: {
                'Content-Type': 'application/json'
            }
        };

        console.log("Sending fetch request to:", url);
        console.log("Request options:", requestOptions);

        // Send logout request to server
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("Response received:", data);
                setAuthenticated(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setAuthenticated(false);
            });

        // localStorage.clear();
    };

    // Component to render a 404 Not Found page
    const NotFound = () => (
        <div style={{ color: "white" }}>
            404 Not Found
            <NavLink style={{ backgroundColor: "darkred", color: "white" }}
                     className="sidebar-item"
                     onClick={handleLogout} to="/">
                Logout
            </NavLink>
        </div>
    );

    // Render subpages for a given page
    const renderSubpages = (page_name, subpages) => {
        return Object.keys(subpages).map(subpageKey => {
            const subpage = subpages[subpageKey];
            return (
                <Route key={subpageKey} path={subpageKey}>
                    <Route index element={<Page containers={subpage.containers} title={page_name} cols={1}
                                                type={subpageKey}/>}/>
                </Route>
            );
        });
    };

    return (
        <div className={isSidebarOpen ? 'padding-on' : 'padding-off'}> {/* Apply padding based on sidebar state */}
            <Routes>
                <Route path="/" element={<Home/>}/> {/* Default route for the Home page */}
                {authenticated && Object.keys(structuredPages).map(pageKey => { // Render routes only if authenticated
                    const page = structuredPages[pageKey];
                    return (
                        <Route key={pageKey} path={pageKey}> {/* Main route for each page */}
                            <Route index element={<Page containers={page.containers} title={pageKey} cols={2}/>}/> {/* Render main page content */}
                            {page.subpages && renderSubpages(pageKey, page.subpages)} {/* Render subpages if they exist */}
                        </Route>
                    );
                })}
                <Route path="/about" element={<About/>}/> {/* Route for About page */}
                <Route path="*" element={<NotFound/>}/> {/* Route for 404 Not Found */}
            </Routes>
        </div>
    );
}

export default PageRoutes;
