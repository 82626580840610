//administration_context.js

import React, {createContext, useContext, useEffect, useState} from 'react';
import {useSidebarContext} from "../Sidebar/sidebar_context";

const AdministrationContext = createContext(undefined);

export const post = async (requestData) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL_ADMINISTRATION, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(requestData),
        });
        // console.log(requestData);
        // console.log(response.json());
        return await response.json();
    } catch (error) {
        console.error('Error sending data to the API', error);
    }

}

export const useAdministrationContext = () => useContext(AdministrationContext);

export const AdministrationContextProvider = ({ children }) => {
    const {user: globalUser} = useSidebarContext();
    const [user, setUser] = useState(globalUser[0]);

    useEffect(() => {
        console.log("global user: ", globalUser)
        console.log("user: ", user)
        if (globalUser && globalUser[0]) {
            setUser(globalUser[0]);
        }
    }, [globalUser]);

    useEffect(() => {
        console.log('globalUser:', globalUser);
        console.log('AdministrationContext user:', user);
    }, [globalUser, user]);

    return (
        <AdministrationContext.Provider value={{ user, setUser }}>
            {children}
        </AdministrationContext.Provider>
    );
};

export default AdministrationContextProvider;