// In src/components/AlertComponent.js
import React, {useEffect, useRef} from 'react';
import {useAlertContext} from '../../Page/alert_context';
import {useLocation} from 'react-router-dom';
import Alert from "@mui/material/Alert";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const AlertComponent = () => {
    const {alerts, closeAlert} = useAlertContext();
    const location = useLocation();
    const prevLocation = useRef(location);


    useEffect(() => {
        if (prevLocation.current !== location) {
            alerts.forEach(alert => closeAlert(alert.id));
            prevLocation.current = location; // Update previous location
        }
    }, [location, alerts, closeAlert]);


    if (alerts.length === 0) {
        return null;
    }

    return (
        <div style={{position: 'fixed', bottom: '2rem', right: 0, width: '25rem', zIndex: 1000}}>
            {alerts.map((alert) => (
                <Alert
                    key={alert.id}
                    severity={alert.severity}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => closeAlert(alert.id)}
                        >
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>
                    }
                    style={{marginBottom: '10px'}}
                >
                    {alert.message}
                </Alert>
            ))}
        </div>
    );
};

export default AlertComponent;
