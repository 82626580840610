import './default_table_style.css'
import {useEffect, useState} from "react";
import {LabelField, PeriodField} from "./custom_fields.js"
import {usePageContext} from "../Page/page_context";
import {useStatusContext} from "../Page/status_context";

const headers = ["Label", "Period"];

const default_data = [
    {label: 'Default', period: 'Forever'}
]

const responseToData = (response) => {
    return Object.keys(response).map(key => {
        const item = response[key];
        let period = item.date['start'] === item.date['end'] ? item?.date['start'] : `${item?.date['start']}-${item?.date['end']}`;
        return {label: key, period: period};
    });
};

const Table = ({game_id}) => {
    const [selectedRowIndex, setSelectedRowIndex] = useState(null); // New state for selected row index
    const {updateCurrentLabel } = usePageContext();
    const {setStatus, setLoading} = useStatusContext(); // Call useStatusContext to get setStatus
    const game_url = process.env.REACT_APP_API_URL_REQUEST + "?gameid=" + game_id;
    const [tableData, setResponseData] = useState(default_data);
    const [label, setLabel] = useState('');
    const {wakeUp, WakeUp} = usePageContext();

    useEffect(() => {
        if (game_id === null) return;
        const fetchData = async () => {
            setLoading(true); // Set loading state to false after login attempt
            setStatus({text: "Fetching data...", color: 2}); // Set status before fetching data
            await fetch(game_url,{
                method:"GET",
                credentials: 'include',
            })
                .then(response => {
                    if (!response.ok) { // Check if the response status is not OK (200-299 range)
                        throw new Error('Network response was not ok: ' + response.status);
                    }
                    return response.json();
                })
                .then(data => {
                    setResponseData(responseToData(data));
                    setStatus({text: "Fetching successful...", color: 0}); // Set status after fetching data
                })
                .catch(error => {
                    console.error('There has been a problem with your fetch operation:', error);
                    setStatus({text: "Fetching unsuccessful...", color: 1}); // Set status after fetching data
                    // Optionally handle the error further, e.g., by setting an error state, logging, etc.
                });
        };
        fetchData();
        setLoading(false);
    }, [game_id, setLoading, setStatus, wakeUp]);

    const handleRowClick = (index) => {
        setSelectedRowIndex(index);
        // Update currentLabel with the selected row's label
        const selectedLabel = tableData[index].label;
        updateCurrentLabel(selectedLabel);
    };

    const handleLabelChange = (e) => {
        setLabel(e.target.value);
    };

    const handleAddClick = async () => {
        if (!label) {
            return;
        }
        let keyvalue = {};

        const addLabel = async () => {
            try {
                setLoading(true);
                setStatus({text: "Adding label...", color: 2});

                // Wait for the fetch to complete
                const response = await fetch(`${game_url}&read=Default`,{
                    method:"GET",
                    credentials: 'include',
                });
                const data = await response.json();
                data['date'] = {'start': '2024-01-01', 'end': '2024-01-01'};
                keyvalue = JSON.stringify(data);

                const addUrl = game_url + `&add=${label}&keyvalue=${encodeURIComponent(keyvalue)}`;

                // Proceed with the second fetch operation
                await fetch(addUrl, {
                    method: 'GET', // or 'POST' if your API supports it
                    credentials: 'include',
                });
                WakeUp();
                setStatus({text: "Label added successfully", color: 0});
            } catch (error) {
                setStatus({text: "Error adding label", color: 1});
                console.error('Error:', error);
            }
            setLoading(false);
        }
        await addLabel();
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <div onClick={handleAddClick} className={"table_add_button"} style={{ marginRight: '6px', marginTop: '20 rem'}}>+</div>
                <input
                    type="text"
                    placeholder="Label..."
                    onChange={handleLabelChange}
                    value={label}
                    className={"table_input_box-2"}
                />
            </div>
            <table className={"table"}>
                <thead className="table_header">
                <tr>
                    {headers.map((header, key) => (
                        <th key={key}>
                            {header}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {tableData.map((row, rowIndex) => (
                    <tr
                        key={rowIndex}
                        onClick={() => handleRowClick(rowIndex)}
                        className={selectedRowIndex === rowIndex ? "selected" : ""}
                    >
                        {Object.values(row).map((cell, cellIndex) => (
                            <td key={cellIndex}>
                                {cellIndex === 0 ? <LabelField text={cell} game_url={game_url}/> : null}
                                {cellIndex === 1 ? <PeriodField date={cell} label={row['label']} game_url={game_url}/> : null}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default Table;

