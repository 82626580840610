import { useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import "./sidebar.css";
import { Gamepad2 } from 'lucide-react';

// Function to determine the styling of a sidebar item based on its active state
const styleSidebarItem = (isActive) => {
    return "sidebar-item " + (isActive ? 'active-link' : 'link');
};

const LinkDrop = ({ Icon, page, base, onNavigate }) => {
    const [popout, setPopout] = useState(false); // State to determine if the popout is open or closed
    const containerRef = useRef(null); // Reference for the whole container (Icon + Popout)

    // Handle mouse events to show/hide popout
    const handleMouseEnter = () => setPopout(true);
    const handleMouseLeave = (event) => {
        // Ensure relatedTarget is a valid Node
        if (!containerRef.current || !event.relatedTarget || !(event.relatedTarget instanceof Node)) {
            setPopout(false);
            return;
        }

        // Check if the mouse is still inside the container
        if (!containerRef.current.contains(event.relatedTarget)) {
            setPopout(false);
        }
    };



    // Handle click event for navigation
    const handleClick = () => {
        onNavigate(); // Call the passed toggle function to handle navigation actions
        setPopout(false); // Close the popout menu
    };

    return (
        <div
            ref={containerRef}
            style={{ position: 'relative' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className={styleSidebarItem(popout)}>
                {Icon ? (
                    <div className="sidebar-image-container">
                        <img src={Icon} alt={`${base} icon`} className="sidebar-images" />
                    </div>
                ) : (
                    <Gamepad2 />
                )}
            </div>

            {popout && (
                <div className="popout">
                    {/* Link to the main page */}
                    <NavLink to={`/${base}`} onClick={handleClick}>
                        Planning
                    </NavLink>
                    {/* Render links for subpages if available */}
                    {page.subpages && Object.keys(page.subpages).map((subpageKey) => (
                        <NavLink key={subpageKey} to={`/${base}/${subpageKey}`} onClick={handleClick}>
                            {subpageKey}
                        </NavLink>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LinkDrop;
