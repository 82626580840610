import React, { useState, useEffect } from "react";
import PageHeader from "../../Components/Page/page_header";
import PageContextProvider from "../../Components/Page/page_context";
import { useSidebarContext } from "../../Components/Sidebar/sidebar_context";
import PageContainer from "../../Components/Page/page_container";
import { NonogramProvider } from "../../Components/Unique/Nonogram/Generator From Image/nonogram_context";
import AdministrationContextProvider from "../../Components/Administration/administration_context";
import PageStatisticsHeader from "../../Components/Page/page_statistics_header";
import { TilesProvider } from "../../Components/Unique/Tiles/tiles_context";

// Mapping of available context providers
export const contextMapping = {
    "Nonogram Context": NonogramProvider,
    "Administration Context": AdministrationContextProvider,
    "Tiles Context": TilesProvider
};

const Page = ({ title, containers, cols, type }) => {
    const { game_id } = useSidebarContext(); // Get game_id from sidebar context

    // Separate containers into those that need context wrapping and those that don't
    let contextContainers = [];
    let nonContextContainers = [];

    if (containers && containers.length > 0) {
        contextContainers = containers.filter(container => container.title.includes("Context")); // Containers requiring context
        nonContextContainers = containers.filter(container => !container.title.includes("Context")); // Containers without context
    }

    // Function to recursively wrap a component in multiple contexts
    const wrapInContexts = (component, contexts) => {
        if (contexts.length === 0) return component;
        const Context = contextMapping[contexts[0].title]; // Get context from mapping
        if (!Context) {
            console.warn(`Context ${contexts[0].title} not found in contextMapping`); // Warn if context not found
            return wrapInContexts(component, contexts.slice(1)); // Skip to the next context
        }
        return (
            <Context>
                {wrapInContexts(component, contexts.slice(1))} {/* Wrap recursively */}
            </Context>
        );
    };

    // Determine the header title based on container availability
    let header;
    if (containers && containers.length === 0) {
        header = title + " - No Access";
    } else {
        header = title;
    }

    // Divide containers into equal parts based on the number of columns (cols)
    const containersPerCol = Math.ceil(nonContextContainers.length / cols);
    const initialContainerCols = Array.from({ length: cols }, (_, i) =>
        nonContextContainers.slice(i * containersPerCol, (i + 1) * containersPerCol)
    );

    const [containerCols, setContainerCols] = useState(initialContainerCols); // State to manage container columns

    // Update container columns whenever containers or cols change
    useEffect(() => {
        setContainerCols(initialContainerCols);
    }, [containers, cols]);

    return wrapInContexts(
        <PageContextProvider> {/* Provide the Page context */}
            <div className={"default_page_style"}> {/* Main page container */}
                <PageHeader title={header} /> {/* Header for the page */}
                {type === "Statistics" ? <PageStatisticsHeader game_id={game_id} /> : null} {/* Show statistics header if type is "Statistics" */}

                <div className="row"> {/* Row for container columns */}
                    {containerCols.map((containers, colIndex) => (
                        <div className="col" key={colIndex}> {/* Column for containers */}
                            {containers.map((container, index) => (
                                <div className="col_full" key={index}> {/* Full-width container */}
                                    <PageContainer title={container.title} game_id={game_id} /> {/* Render PageContainer with title and game_id */}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </PageContextProvider>,
        contextContainers
    );
};

export default Page;
