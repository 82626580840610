import React, {useEffect, useState} from 'react';
import Plot from 'react-plotly.js';
import "./statistics.css";
import {useSidebarContext} from "../Sidebar/sidebar_context";
import DownloadCSVButton from "../Unique/Misc/download_csv_button";
import {useStatisticsContext} from "./statistics_context";
import {Box, Checkbox, FormControlLabel, Grid} from "@mui/material";


const options = [
    "Total Unique Users who entered the game",
    "Total Unique Users who played the daily challenge",
    "Percentage of Users who completed the daily challenge", // make percentage
    "Percentage of Users who gave up on the daily challenge", // make percentage
    // "Average Score", //remove from lambda
    // "Total Score", //remove from lambda
    "Total Time for entire game", //Average Time
    // "Total Time", //remove from lambda
    "Total Gameplays",
    "Total Rewarded Retries in Daily Challenge",
    "Total Rewarded Retries in Freeplay",
    "Total Freeplay Gameplays",
    "Total Midroll Ads",
    //"Total Daily Challenge Gameplays", // needs to be added in lambda.
    "Total Ads"
];

const GraphWithOptions = ({game_id}) => {
    const [selectedStatistics, setSelectedStatistics] = useState(["Total Unique Users who entered the game"]);
    const [selectedOptions, setSelectedOptions] = useState({
        "Total Unique Users who entered the game": true,
        "Total Unique Users who played the daily challenge": false,
        "Percentage of Users who completed the daily challenge": false,
        "Percentage of Users who gave up on the daily challenge": false,
        // "Average Score": false,
        // "Total Score": false,
        "Total Time for entire game": false,
        // "Total Time": false,
        // "Average Daily Complete": false,
        // "Average Daily Players": false,
        // "Total Feedback": false,
        // "Average Stars": false,
        "Total Gameplays": false,
        "Total Rewarded Retries in Daily Challenge": false,
        "Total Rewarded Retries in Freeplay": false,
        "Total Freeplay Gameplays": false,
        "Total Midroll Ads": false,
        // "Total Daily Challenge Gameplays": false,
        "Total Ads": false
    });
    const [data, setData] = useState([]);
    const [layout, setLayout] = useState({});
    const [cachedGeneralData, setCachedGeneralData] = useState(null);
    const [isGeneralDataLoaded, setIsGeneralDataLoaded] = useState(false);
    // const [oldGame_id, setOldGame_id] = useState(null);

    const {brand, dates, setAuthenticated} = useSidebarContext();
    const {generalStatistics, setGeneralStatistics, setGeneralStatsLoading} = useStatisticsContext();
    const today = new Date();
    const oneWeekAgo = new Date(new Date().setDate(today.getDate() - 7));

    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

// Format the date to YYYY-MM-DD
    let yesterdayFormatted = yesterday.toISOString().split('T')[0];

    // console.log("game_id graph with options: "+game_id);

    useEffect(() => {
        const controller = new AbortController();
        const {signal} = controller;

        const fetchGeneralData = async () => {
            setGeneralStatsLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL_STATISTICS}?method=getGeneralStatisticsNew&gameid=${game_id}&date=${dates[0]}_${yesterdayFormatted}`, {
                    signal,
                    credentials: 'include',
                });
                if (response.status !== 200) {
                    if (response.status === 401) {
                        setAuthenticated(false);
                    }
                    // Log all headers if the response status is not 200
                    // response.headers.forEach((value, key) => {
                    //     console.log(`${key}: ${value}`);
                    // });
                    console.log(response);
                    throw new Error(`API call failed with status: ${response.body}`);
                }
                const data = await response.json();
                if (!signal.aborted) {
                    setCachedGeneralData(data);
                    setGeneralStatistics(data);
                    setIsGeneralDataLoaded(true);
                }
            } catch (error) {
                if (!signal.aborted) {
                    console.error("Failed to fetch data:", error);
                }
            } finally {
                if (!signal.aborted) {
                    setGeneralStatsLoading(false);
                }
            }
        };

        fetchGeneralData();

        // Cleanup function to abort the fetch operation when component unmounts or dependencies change
        return () => {
            // console.log("Aborting fetch for game_id:", game_id);
            controller.abort();
        };
    }, [dates, game_id, brand]); // Removed isGeneralDataLoaded from dependencies


    useEffect(() => {
        const isMobile = window.matchMedia("(max-width: 767px)").matches;
        if (game_id === null) return;
        if (!isGeneralDataLoaded) return;
        const plotData = selectedStatistics.map(value => ({
            type: 'scatter',
            mode: 'lines+markers',
            x: generalStatistics.date,
            y: generalStatistics[value],
            line: {shape: 'spline', smoothing: 1},
            marker: {size: 5},
            name: value
        }));
        setData(plotData);
        setLayout({
            xaxis: {
                tickformat: "%b %d %Y", // This will format your ticks to "Nov 5 2023", "Nov 19", etc.
                // tickformat: "%b" // This will format your ticks to "Nov 5 2023", "Nov 19", etc.
                autorange: !isMobile,
                range: isMobile ? [oneWeekAgo.toISOString().split('T')[0], new Date().toISOString().split('T')[0]] : undefined,
            },
            yaxis: {
                autorange: true, // This line allows Plotly to automatically adjust the range
                rangemode: 'tozero' // Ensures that the y-axis starts at 0
            },
            legend: {
                y: -0.1, // Moves the legend below the plot area
                x: 0.5, // Centers the legend
                xanchor: 'center',
                yanchor: 'top',
                orientation: 'h' // Makes the legend horizontal
            },
            autosize: true,
            margin: {l: 30, r: 0, b: 30, t: 30, pad: 4},
            overflow: 'scroll'
        });
        setGeneralStatsLoading(false);

    }, [selectedOptions, selectedStatistics, isGeneralDataLoaded, generalStatistics]);

    const handleCheckboxChange = (option) => {
        // Update the selected options
        setSelectedOptions(prev => ({
            ...prev,
            [option]: !prev[option]  // Toggle the checkbox state for the given option
        }));

        // Update the selected statistics
        setSelectedStatistics(prev => {
            const currentIndex = prev.indexOf(option);
            if (currentIndex > -1) {
                // If the option is already selected, remove it
                return prev.filter(stat => stat !== option);
            } else {
                // If the option is not selected, add it
                return [...prev, option];
            }
        });
    };


    return (
        <Box className="statistics-container">
            <Grid container spacing={2} overflow={"auto"}>
                <Grid item xs={12} md={1} lg={3}>
                    {options.map(option => (
                        <FormControlLabel
                            key={option}
                            control={
                                <Checkbox
                                    className="custom-checkbox"
                                    checked={selectedOptions[option]}
                                    onChange={() => handleCheckboxChange(option)}
                                    name={option}
                                />
                            }
                            label={option}
                        />
                    ))}
                </Grid>
                <Grid item xs={13} md={10} lg={9}>
                    <Box className="plot-container" width="100%" paddingRight="10px" justifyContent="center">
                        <Plot
                            data={data}
                            layout={layout}
                            useResizeHandler={true}
                            style={{width: '100%', height: '100%'}}
                            loading="Loading_icon..."
                        />
                    </Box>
                </Grid>
            </Grid>
            <div className="csv-download-button">
                <DownloadCSVButton data={generalStatistics} fileName={"General Stats for Game ID " + game_id}/>
            </div>
        </Box>
    );
};

export default GraphWithOptions;
