import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import Alert from '@mui/material/Alert';
import { useStatusContext } from "../../Page/status_context";
import { useTilesContext } from "../../Unique/Tiles/tiles_context";
import { useAlertContext } from "../../Page/alert_context";

const FileUploader = () => {
    const { setArchive } = useTilesContext();
    const [encodedFiles, setEncodedFiles] = useState([]);
    const [errors, setErrors] = useState([]);
    const { showAlert } = useAlertContext();
    const { setStatus, setLoading } = useStatusContext();

    useEffect(() => {
        if (errors.length > 0) {
            const timer = setTimeout(() => setErrors([]), 5000);
            return () => clearTimeout(timer);
        }
    }, [errors]);

    const handleFileInput = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        if (!isZipFile(file)) return;
        setArchive(file);
        unzipAndProcessFile(file);
    };

    const isZipFile = (file) => {

        if (!file.name.endsWith('.zip')) {
            showAlert(<span>The selected file is not a zip file!</span>, 'error');
            return false;
        }
        return true;
    };

    const unzipAndProcessFile = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            processZipFile(event.target.result);
        };
        reader.readAsBinaryString(file);
    };

    const processZipFile = (fileData) => {
        JSZip.loadAsync(fileData).then((zip) => {
            const baseFolderName = getBaseFolderName(zip);
            const newErrors = [];

            if (!baseFolderName) {
                newErrors.push("Base folder with Icon.png not found.");
            }

            if (!isZipStructureCorrect(zip, baseFolderName, newErrors)) {
                handleZipError(newErrors);
                return;
            }

            if (newErrors.length === 0) {
                encodeFiles(zip);
            } else {
                setErrors(newErrors);
            }
        });
    };

    const getBaseFolderName = (zip) => {
        try {
            const baseFolderName = Object.keys(zip.files).find(filename => filename.endsWith('Icon.png')).split('/')[0];
            return baseFolderName;
        } catch (e) {
            return null;
        }
    };

    const handleZipError = (errorList) => {
        showAlert("The structure of the zip file is incorrect!", 'error');
        setArchive([]);
        setErrors(errorList);
    };

    const encodeFiles = (zip) => {
        const filePromises = Object.keys(zip.files).map(filename =>
            zip.files[filename].async("base64").then(content => ({ filename, content }))
        );

        Promise.all(filePromises).then(encodedFiles => {
            setEncodedFiles(encodedFiles);
            showAlert("The structure of the zip file is correct!", 'success');
        });
    };

    const isZipStructureCorrect = (zip, baseFolderName, errorList) => {
        if (!zip.files[`${baseFolderName}/Icon.png`]) {
            errorList.push("Icon.png is missing.");
        }

        const expectedFolderCount = parseInt(baseFolderName.split('-').pop(), 10);
        if (isNaN(expectedFolderCount)) {
            errorList.push("Invalid folder name format: could not extract expected folder count.");
        }

        if (!validateTileStructure(zip, baseFolderName, expectedFolderCount, errorList)) return false;

        return errorList.length === 0;
    };

    const validateTileStructure = (zip, baseFolderName, expectedFolderCount, errorList) => {
        if (!zip.files[`${baseFolderName}/0/Tile_0.png`] || !zip.files[`${baseFolderName}/0/Tile_1.png`]) {
            errorList.push(`Tile_0.png or Tile_1.png is missing in folder ${baseFolderName}/0.`);
        }

        for (let i = 1; i < expectedFolderCount; i++) {
            for (let j = 0; j < 10; j++) {
                if (!zip.files[`${baseFolderName}/${i}/Tile_${j}.png`]) {
                    errorList.push(`Tile_${j}.png is missing in folder ${baseFolderName}/${i}.`);
                }
            }
        }

        return true;
    };

    const uploadFiles = () => {
        if (!encodedFiles.length) {
            setErrors(["No (valid) files selected!"]);
            showAlert(<span>No (valid) files selected!</span>, 'error');
            return;
        }
        initiateUpload();
    };

    const initiateUpload = () => {
        setErrors([]);
        setStatus({ text: "Uploading...", color: 1 });
        setLoading(true);

        encodedFiles.forEach(uploadFile);

        setStatus({ text: "Uploading successful...", color: 0 });
        showAlert(<span>Successfully uploaded theme to bucket!</span>, 'success');
        setLoading(false);
    };

    const uploadFile = (file) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ method: 'uploadFile', filename: file.filename, content: file.content }),
            credentials: 'include',
        };
        fetch(process.env.REACT_APP_API_URL_S3, requestOptions)
            .then(response => response.json());
    };

    return (
        <div className={"button-stretch"}>
            {errors.length > 0 &&
                <div>
                    {errors.map((error, index) => (
                        <Alert key={index} severity="error">{error}</Alert>
                    ))}
                </div>
            }
            <input type="file" onChange={handleFileInput} />
            <button className={"Button"} onClick={uploadFiles}> Upload to S3</button>
        </div>
    );
};

export default FileUploader;
