import React, {createContext, useContext, useState} from 'react';

export const NonogramContext = createContext(undefined);
export const useNonogramContext = () => useContext(NonogramContext);


export const NonogramProvider = ({ children }) => {
    const [bwValues, setBWValues] = useState([]);
    const [rgbValues, setRGBValues] = useState([]);
    const [pixelArtSize, setPixelArtSize] = useState(15);
    const [isValid, setIsValid] = useState(false);
    const [newUploadedNono, setNewUploadedNono] = useState({name: '', data: '', date: null, gameid: null});
    const [selectedNonogram, setSelectedNonogram] = useState(null);

    return (
        <NonogramContext.Provider value={{ bwValues, setBWValues, rgbValues, setRGBValues, pixelArtSize, setPixelArtSize, isValid, setIsValid, newUploadedNono, setNewUploadedNono, selectedNonogram, setSelectedNonogram}}>
            {children}
        </NonogramContext.Provider>
    );
};