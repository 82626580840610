// Home.js component

// Importing styles and necessary components for Home page layout
import "../../Components/Unique/Login/login_form.css";
import LoginForm from "../../Components/Unique/Login/login_form"; // Importing LoginForm component
import PageHeader from "../../Components/Page/page_header"; // Importing PageHeader component
import {useEffect, useState} from "react"; // Importing React hooks
import {useSidebarContext} from "../../Components/Sidebar/sidebar_context"; // Context for managing sidebar state
import logo from './../../Components/Assets/Denda_New3.png'; // Importing logo image
import phone_top from '../../Components/Assets/phone_top.png'; // Importing top phone image
import phone_mid from '../../Components/Assets/phone_mid.png'; // Importing middle phone image
import phone_low from '../../Components/Assets/phone_low.png'; // Importing bottom phone image
import stonks_mini from '../../Components/Assets/stonks_mini.png'; // Importing mobile version of stonks image
import "./home.css"; // Importing specific styles for Home page

const Home = () => {
    // States for controlling the visibility of welcome message and login form
    const [showWelcome, setShowWelcome] = useState(false);
    const [showLoginForm, setShowLoginForm] = useState(false);

    // Getting the authentication and sidebar functions from context
    const {authenticated, setSidebarOpen} = useSidebarContext();
    const {toggleMobileSidebar} = useSidebarContext();

    // State for controlling the mobile sidebar toggle
    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

    // useEffect to trigger animations on component mount
    useEffect(() => {
        // Show welcome message when component mounts
        setShowWelcome(true);

        // Delay showing the login form for animation effect
        const timer = setTimeout(() => setShowLoginForm(true), 500); // Adjusting the delay for the form

        // Clear the timer when the component is unmounted
        return () => clearTimeout(timer);
    }, []);

    // useEffect to handle authentication changes
    useEffect(() => {
        if (authenticated) {
            // Hide welcome message and login form if authenticated
            setShowWelcome(false);
            setShowLoginForm(false);
            setSidebarOpen(true); // Open sidebar when user is authenticated
        } else {
            // Show welcome message and login form if not authenticated
            setShowWelcome(true);
            setShowLoginForm(true);
        }
    }, [authenticated, setSidebarOpen]); // Effect runs when authentication state or sidebar state changes

    return (
        <div style={{overflowX: "visible", overflowY: "hidden"}}> {/* Controlling overflow behavior of the home page */}
            {authenticated ? ( // Check if the user is authenticated
                <>
                    {/* Show different background images based on screen size */}
                    <img className="hide-mobile background-image" src={stonks_mini} alt="Stonks"/> {/* Display only on desktop */}

                    <div className={"default_page_style"}>
                        <PageHeader title="Home" toggleSidebar={toggleMobileSidebar}/> {/* Render page header and toggle sidebar */}

                        {/* Welcome message for authenticated users */}
                        <div className="welcome-message">
                            <span className="welcome-quote">“</span>
                            <span>{("Welcome to the")} </span>
                            <span className="welcome-bold">{("Denda Content Management Console")}</span>
                            <span>{(", where seamless control meets boundless creativity.")}</span>
                            <span className="welcome-quote">”</span>
                        </div>

                        {/* Phone images only displayed on desktop */}
                        <div className="d-none d-md-block">
                            <img className="phone-image phone-image-top" src={phone_top} alt="Phone"/> {/* Top section of phone */}
                            <img className="phone-image phone-image-middle" src={phone_mid} alt="Phone"/> {/* Middle section of phone */}
                            <img className="phone-image phone-image-bottom" src={phone_low} alt="Phone"/> {/* Bottom section of phone */}
                        </div>
                    </div>
                </>
            ) : ( // If not authenticated, show login form
                <div className= "login-page" >
                    <div className="section1"> {/* New container */}
                        <div className="denda">
                            <h2> Denda </h2>
                        </div>
                        <div className="login-logo">
                            <img src={logo} alt="Logo" className="logo"/> {/* Display company logo */}
                        </div>

                        {showWelcome && ( // Show welcome message before login form
                            <div className="welcome-message-login">
                               <h4> Welcome to the Denda Content Management Console </h4>
                            </div>
                        )}
                    </div>
                    <div className="section2">
                        {showLoginForm && ( // Show login form after animation delay
                            <div className="login-form">
                                <LoginForm/> {/* Render LoginForm component */}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Home;
