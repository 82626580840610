//page_header.js

import React, {useEffect, useRef, useState} from "react";
import {useSidebarContext} from "../Sidebar/sidebar_context";
import Divider from '@mui/material/Divider';
import './page_header.css';
import Profile from "../../Pages/Settings/user_profile"


// Filters games based on the provided game name
const filterGames = (games, gameName) => {
    return (games || []).filter(game => game.name === gameName)
        .map(game => ({game_id: game.game_id, version: game.version}));
}

// Gets the game ID based on game name and version
const getGameId = (games, gameName, gameVersion) => {
    let game = (games || []).find(game => game.name === gameName && game.version === gameVersion);
    if (!game) {
        game = (games || []).find(game => game.name === gameName);
    }
    return game ? game.game_id : null;
}

// PageHeader component to display the page header with dropdown and sidebar toggle
const PageHeader = ({title, toggleSidebar}) => {
    const {game_id, brand, setGame_id, games} = useSidebarContext();
    const [pageGames, setPageGames] = useState(filterGames(games, title)); // State for filtered games based on title
    const [showDropdown, setShowDropdown] = useState(false); // State to show/hide dropdown menu
    const dropdownRef = useRef(null); // Ref for the dropdown
    const {isSidebarOpen, toggleMobileSidebar} = useSidebarContext(); // This is assumed context API state
    const [version, setVersion] = useState(""); // State to store selected game version
    const [showHeader, setShowHeader] = useState(true); // State to control header visibility on scroll
    const lastScrollY = useRef(0); // Ref to track the last scroll position

    // Handle visibility of header when scrolling
    useEffect(() => {
            const controlHeaderVisibility = () => {
                const shouldHideHeader = window.scrollY > lastScrollY.current && window.scrollY > 50;
                setShowHeader(!shouldHideHeader || isSidebarOpen);
                lastScrollY.current = window.scrollY;
            };

            window.addEventListener('scroll', controlHeaderVisibility);

            return () => {
                window.removeEventListener('scroll', controlHeaderVisibility);
            };
        }, [isSidebarOpen]
    ); // Add isSidebarOpen as a dependency

    // Handle closing dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    // Update version based on game_id when pageGames change
    useEffect(() => {
        const game = pageGames.find(game => game.game_id === game_id);
        if (game) {
            setVersion(game.version);
        }
    }, pageGames);

    // Update game_id and version based on title change
    useEffect(() => {
        if (games) {
            const gameId = getGameId(games, title, brand);
            if (gameId !== null) {
                setGame_id(gameId);
            }
            setPageGames(filterGames(games, title));
        }
        if (version !== "" && game_id !== null) {
            let gameFromId = games.find(game => game.game_id === game_id);
            if (gameFromId !== null) {
                setVersion(gameFromId["version"])
            }
        }
    }, [title]);

    // Handle dropdown visibility when clicking on dropdown button
    const handleDropdown = (event) => {
        event.preventDefault();  // To prevent default behavior like text selection
        event.stopPropagation(); // Prevent the event from bubbling up to parent elements
        if (games && games.length > 1) {
            setShowDropdown(prevShowDropdown => !prevShowDropdown);
        }
    };

    // Handle game selection from the dropdown menu
    const handleGameSelect = (id) => {
        setGame_id(id);
        setShowDropdown(false);
        let gameFromId = games.find(game => game.game_id === id);
        setVersion(gameFromId["version"])
    };

    const handleVersionChange = (event) => {
        const selectedVersion = event.target.value;
        setVersion(selectedVersion);
        const selectedGame = pageGames.find(game => game.version === selectedVersion);
        if (selectedGame) {
            setGame_id(selectedGame.game_id);
        }
    };

    return (
        <div
            className={`header-container ${showHeader ? '' : 'hide-header'}`}>
            <div className="title-with-dropdown">

                <h1 className={"d-none d-md-block"}> {brand === "Anonymous" ? "DPG" : brand} </h1>

                <Divider orientation="vertical" variant="middle"/>

                <h1>  {title} </h1>

                {/* Conditionally render the dropdown menu */}
                {showDropdown && pageGames && pageGames.length > 0 && (
                    <div className="dropdown-menu-denda" ref={dropdownRef}>
                        {pageGames.map(game => (
                            <div key={game.game_id}
                                 onClick={() => handleGameSelect(game.game_id) && toggleMobileSidebar}>
                                {game.version}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="title-right">

                {/* Show the dropdown button if there is more than one version of the game */}
                {pageGames && pageGames.length > 1 && (
                    <select value={version} onChange={handleVersionChange} className="version-select">
                        {pageGames.map(game => (
                            <option key={game.game_id} value={game.version}>
                                {game.version}
                            </option>
                        ))}
                    </select>
                )}

                <div>
                    <Profile/>
                </div>

                {/* Mobile burger menu icon to toggle the sidebar */}
                <div className="burger-mobile d-block d-sm-block d-md-none" onClick={toggleMobileSidebar}>
                    <div className="burger-line"></div>
                    <div className="burger-line"></div>
                    <div className="burger-line"></div>
                </div>
            </div>
        </div>
    );
};

export default PageHeader;
