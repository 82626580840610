import React, {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';

export const StatusContext = createContext(undefined);

export const useStatusContext = () => useContext(StatusContext);

export const StatusProvider = ({children}) => {
    const [status, setStatus] = useState({text: "", color: 0});
    const [loading, setLoading] = useState(false); // State for controlling the loading screen

    return (
        <StatusContext.Provider value={{status, setStatus, loading, setLoading}}>
            {children}
        </StatusContext.Provider>
    );
};

StatusProvider.propTypes = {
    children: PropTypes.node.isRequired,
};