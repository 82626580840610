import React, {createContext, useContext, useState} from 'react';

const AlertContext = createContext();

export const useAlertContext = () => useContext(AlertContext);

export const AlertProvider = ({children}) => {
    const [alerts, setAlerts] = useState([]);


    // Show alert function adds new alerts to the state
    const showAlert = (message, severity = 'info') => {
        const newAlert = {
            id: Date.now(),
            message,
            severity,
        };
        setAlerts(currentAlerts => [newAlert, ...currentAlerts]);
    };

    // Close alert function filters out an alert by id
    const closeAlert = (id) => {
        setAlerts(currentAlerts => currentAlerts.filter(alert => alert.id !== id));
    };

    // Pass everything needed via context so it can be used in components
    return (
        <AlertContext.Provider value={{alerts, showAlert, closeAlert}}>
            {children}
        </AlertContext.Provider>
    );
};

export default AlertProvider;
