import React, {useEffect, useRef} from 'react';
import {useTilesContext} from "./tiles_context";
import './preview.css'; // Make sure to create this CSS file in the same directory

const TilesetPreview = () => {
    const {images} = useTilesContext();
    const canvasRef = useRef(null); // Reference to the canvas


    useEffect(() => {
        if (images) {
            drawRandomImages(images);
        }
    } , [images]);
    const drawRandomImages = (imagesByFolder) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let folderNames = Object.keys(imagesByFolder); // Get all the folder names
        folderNames = folderNames.sort((a, b) => {
            const numA = parseInt(a.replace('Layer ', ''), 10);
            const numB = parseInt(b.replace('Layer ', ''), 10);
            return numA - numB;
        });

        for (let row = 0; row < 5; row++) {
            for (let col = 0; col < 5; col++) {
                for (const folderName of folderNames) {
                    if (folderName === 'Icon') continue; // Skip the icon folder
                    if (folderName === 'Layer 0') {} // Alternate between images[0] and images[1]
                    const images = imagesByFolder[folderName];
                    if (images.length > 0) {
                        let randomImage;
                        if (folderName === 'Layer 0') {
                            // Alternate between images[0] and images[1] based on the sum of row and col
                            randomImage = (row + col) % 2 === 0 ? images[0] : images[1];
                        } else {
                            const randomIndex = Math.floor(Math.random() * images.length);
                            randomImage = images[randomIndex];
                        }
                        if (!randomImage) return;
                        const image = new Image();
                        image.src = randomImage.url;
                        image.onload = () => {
                            // Draw the image on the canvas at the correct position
                            ctx.drawImage(image, col * 100, row * 100, 100, 100);
                        };
                        // console.log("Drawing image from folder", folderName);
                    }
                }
            }
        }
    };

    return (
        <div>
            <canvas ref={canvasRef} width="500" height="500" className={"tiles-canvas"}></canvas>
        </div>
    );
}

export default TilesetPreview;
