import React, {createContext, useContext, useEffect, useState} from 'react';
import JSZip from "jszip";

export const TilesContext = createContext(undefined);
export const useTilesContext = () => useContext(TilesContext);


export const TilesProvider = ({children}) => {
    const [archive, setArchive] = useState([]);
    const [images, setImages] = useState({}); // This will store the images categorized by folders

    useEffect(() => {
        if (archive && archive.name) {
            // Check if the file is a zip file
            if (!archive.name.endsWith('.zip')) {
                console.error("Uploaded file is not a zip file.");
                return;
            }

            const newZip = new JSZip();
            try {
                newZip.loadAsync(archive)
                    .then(zip => {
                        const filePromises = [];
                        const imagesByFolder = {}; // This object will store arrays of images for each folder

                        zip.forEach((relativePath, zipEntry) => {
                            if (zipEntry.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
                                let folderName = zipEntry.name.split('/')[1]; // Get the folder name
                                if (/^\d/.test(folderName)) { // Check if folder name starts with a number
                                    folderName = `Layer ${folderName}`; // Rename folder
                                } else if (folderName === 'Icon.png') {
                                    folderName = 'Icon'; // Rename folder
                                } else return;
                                filePromises.push(zipEntry.async("blob").then(blob => {
                                    if (!imagesByFolder[folderName]) {
                                        imagesByFolder[folderName] = []; // If the folder doesn't exist, create it
                                    }
                                    imagesByFolder[folderName].push({
                                        name: zipEntry.name.split('/').pop(), // Get just the file name
                                        url: URL.createObjectURL(blob) // Create a URL for the blob
                                    });
                                    // console.log(folderName)
                                }));
                            }

                        });

                        Promise.all(filePromises).then(() => {
                            setImages(imagesByFolder); // Set the state with the organized images
                        });

                    })
                    .catch(err => {
                        console.error("Error reading zip file: ", err);
                    });
            } catch (err) {
                console.error("Error loading zip file: ", err);
            }
        }
    }, [archive]);


    return (
        <TilesContext.Provider value={{
            archive, setArchive,
            images, setImages
        }}>
            {children}
        </TilesContext.Provider>
    );
};