import Table from "..//Table/table_labels"

const LabelManager = ({game_id}) => {
    return (
        <div>
            <Table game_id={game_id}/>
        </div>
    )
}

export default LabelManager