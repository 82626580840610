//user_manager.js
// Description: This file contains the user manager component which is used to manage users and their passwords.
// It uses the administration_context.js file to get the user data and post data to the API.

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import {useEffect, useState} from "react";
import {post, useAdministrationContext} from "./administration_context";
import crypto from "crypto";
import {useAlertContext} from "../Page/alert_context";
import "../Main/default_component_style.css";
import './user_manager.css';


const UserManager = () => {
    const {user, setUser} = useAdministrationContext();
    const [password, setPassword] = useState('');
    const [newUsername, setNewUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [users, setUsers] = useState([]);

    const {showAlert} = useAlertContext();

    useEffect(() => {
        const fetchData = async () => {
            const getUsers = await post({method: 'getUsers'});
            setUsers(getUsers);
        };

        fetchData();
    }, []);

    function getUsernameById(userId) {
        const user = users.find(user => user[0] === userId);
        return user ? user[1] : 'notfound'; // Returns the username if found, otherwise null
    }

    useEffect(() => {
        // console.log(users);
        if (users.length > 0) {
            showAlert(<span>Changed to user <strong>{getUsernameById(user)}</strong> (ID: {user})! Any changes made in Page Manager or Change password are made for this user!</span>, "warning");
        }
        // console.log(user);
    }, [user])

    const handleUserChange = (event) => {
        setUser(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleNewUsernameChange = (event) => {
        setNewUsername(event.target.value);
    };

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleAddUser = () => {

        if (newUsername.length < 1 || newPassword.length < 1) {
            //showAlert(<span><strong>Both username and password need to have entries!</strong></span>,"error");
            return;
        }
        const hashPassword = (password) => {
            const hash = crypto.createHash('sha256');
            hash.update(password);
            return hash.digest('hex');
        };

        const hashedPassword = hashPassword(newPassword);
        post({method: 'addUser', 'username': newUsername, 'password': hashedPassword});
        //showAlert(<span>new user <strong>{newUsername}</strong> added!</span>,"success");
    };

    const handleChangePassword = () => {
        // Handle submit logic here
        // console.log("User added:", newUsername, newPassword);
        //showAlert(<span><strong>Changing Password doesn't have an implementation yet! Will come soon!</strong></span>,"error");
    };

    return (
        <div className="user-manager">
            <div className="user-manager-header">
                <h2>Change Password</h2>
            </div>
            <div className={"stacked"}>
                <FormControl sx={{m: 1, minWidth: 120}}>
                    <InputLabel id="user-select-label">User</InputLabel>
                    <Select
                        labelId="user-select-label"
                        id="user-select"
                        value={user}
                        onChange={handleUserChange}
                        autoWidth
                        label="User"
                    >
                        {users.map((user, index) => (
                            <MenuItem key={index} value={user[0]}>
                                {user[1]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label="New Password"
                    variant="outlined"
                    value={password}
                    onChange={handlePasswordChange}
                    sx={{m: 1}}
                />

                <button
                    className="Button"
                    style={{marginTop: '15px'}}
                    onClick={handleChangePassword}
                >
                    Submit
                </button>
            </div>
            <Divider sx={{my: 2}}/>

            <div className="user-manager-header">
                <h2>Add User</h2>
            </div>
            <div className={"stacked"}>
            <TextField
                label="Username"
                variant="outlined"
                value={newUsername}
                onChange={handleNewUsernameChange}
                sx={{m: 1}}
            />
            <TextField
                label="Password"
                type="password"
                variant="outlined"
                value={newPassword}
                onChange={handleNewPasswordChange}
                sx={{m: 1}}
            />
            <button
                className="Button"
                style={{marginTop: '15px'}}
                onClick={handleAddUser}
            >
                Submit
            </button>
                </div>
        </div>
    );
}

export default UserManager;
