import React, {useEffect, useState} from "react";

const ChallengeModifierHolder = ({dataKey, data, eventTrigger}) => {
    data = data[dataKey];
    const [range, setRange] = useState({min: data["min"], max: data["max"]});
    const [enabled, setEnabled] = useState(data["enabled"]);

    useEffect(() => {
        setRange({min: data["min"], max: data["max"]});
        setEnabled(data["enabled"]);
    }, [data]);
    const handleCheckboxChange = () => {
        setEnabled(!enabled);
        eventTrigger(!enabled, dataKey, 'enabled');
    };

    const handleMinChange = (event) => {
        setRange({...range, min: event.target.value});
        eventTrigger(event.target.value, dataKey, 'min');
    }

    const handleMaxChange = (event) => {
        setRange({...range, max: event.target.value});
        eventTrigger(event.target.value, dataKey, 'max');
    }
    return (
        <div>
            <label>
                <input
                    type="checkbox"
                    name="score"
                    checked={enabled}

                    onChange={handleCheckboxChange}
                />
                {data['description']}:
                <input
                    type="number"
                    name="min"
                    value={range.min}
                    onChange={handleMinChange}
                    disabled={!enabled}
                />
                to
                <input
                    type="number"
                    name="max"
                    value={range.max}
                    onChange={handleMaxChange}
                    disabled={!enabled}
                />
                {data['unit']}
            </label>
        </div>
    )
}

export default ChallengeModifierHolder;