import React, { useEffect, useRef, useState } from 'react';
import { useNonogramContext } from "./nonogram_context";
import { Checkbox, Input, Slider } from "@mui/material";
import { useAlertContext } from "../../../Page/alert_context";
import './nonogram.css';

// Component for generating and uploading a Nonogram puzzle
const NonogramGenerator = ({ game_id,  initialNonogramData, initialChallengeName, onUpdate }) => {
    // State variables
    const [imageFile, setImageFile] = useState(null);          // Holds the uploaded image file
    const [image, setImage] = useState(null);                  // Image object created from the uploaded file
    const canvasRef = useRef(null);                            // Reference to the hidden canvas for processing
    const displayCanvasRef = useRef(null);                     // Reference to the display canvas
    const bwCanvasRef = useRef(null);                          // Reference to the black and white canvas
    const displaySize = 255;                                   // Size of the display canvas
    const [tolerance, setTolerance] = useState(0);             // Tolerance for converting image to black and white
    const [isSameSize, setIsSameSize] = useState(false);       // Flag to use the image's original size
    const [challenge_name, setChallengeName] = useState(initialChallengeName || "");   // Name of the Nonogram challenge
    const [activeButton, setActiveButton] = useState("5");     // Active pixel size button ("5", "10", or "15")
    const [showDisplayCanvas, setShowDisplayCanvas] = useState(true);
    useEffect(() => {
        if(initialNonogramData){
            loadNonogramData(initialNonogramData);
        }
    }, [initialNonogramData]);
    // Context from NonogramContext
    const {
        bwValues,            // Array of black and white pixel values
        setBWValues,
        rgbValues,           // Array of RGB pixel values
        setRGBValues,
        pixelArtSize,        // Size of the pixel art grid
        setPixelArtSize,
        isValid,             // Flag indicating if the Nonogram is valid
        setNewUploadedNono   // Function to set the new uploaded Nonogram
    } = useNonogramContext();

    // Context for displaying alerts
    const { showAlert } = useAlertContext();

    const loadNonogramData = (data) => {
        // Split the input data string into an array of numbers

        // for (let i = data[0]; i < data.size; i++) {
        //     console.log(data[i]);
        // }
        const bwArray = data.split(',').map(Number);

        // Calculate the size of the grid (assuming it's a square grid)
        const size = Math.sqrt(bwArray.length);

        // Create a new canvas element to draw the image
        const canvas = document.createElement('canvas');
        canvas.width = size;    // Set canvas width to grid size
        canvas.height = size;   // Set canvas height to grid size
        const ctx = canvas.getContext('2d'); // Get the 2D drawing context

        // Create a new ImageData object to store pixel data
        const imageData = ctx.createImageData(size, size);

        // Loop through each cell in the bwArray to set pixel colors
        for (let i = 0; i < bwArray.length; i++) {
            // Determine the color based on the cell value (1 for black, 0 for white)
            const color = bwArray[i] === 1 ? 0 : 255; // 0 for black, 255 for white

            // Each pixel consists of 4 values (RGBA), so calculate the starting index
            const index = i * 4;

            // Set the pixel color in the imageData array
            imageData.data[index] = color;       // Red channel
            imageData.data[index + 1] = color;   // Green channel
            imageData.data[index + 2] = color;   // Blue channel
            imageData.data[index + 3] = 255;     // Alpha channel (fully opaque)
        }

        // Put the image data onto the canvas at position (0, 0)
        ctx.putImageData(imageData, 0, 0);

        // Convert the canvas content to a Data URL (base64 encoded image)
        const dataURL = canvas.toDataURL();

        // Create a new Image object
        const img = new Image();
        img.onload = () => {
            // When the image is loaded, set it to the state variable 'image'
            setImage(img);
        };
        // Start loading the image by setting its source to the Data URL
        img.src = dataURL;
    };


    // Function to post the Nonogram challenge to the server
    const postChallenge = async (game, variant_id, challenge_name, variant_name, data) => {
        const url = process.env.REACT_APP_API_URL_CHALLENGES; // API endpoint URL
        const method = "addChallenge";
        const body = {
            "method": method,
            "game": game,
            "variant_id": variant_id,
            "challenge_name": challenge_name,
            "variant_name": variant_name,
            "data": data
        };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
            credentials: 'include',
        };

        try {
            const response = await fetch(url, options);
            const json = await response.json(); // Parse JSON response
            if (!response.ok) {
                throw new Error(json.message || "Unknown error occurred");
            }
            return json;
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            throw error; // Re-throw error to be caught by caller
        }
    };


    // Function to update an existing Nonogram challenge on the server
    const updateChallenge = async (game_id, challenge_name, data) => {
        const url = process.env.REACT_APP_API_URL_CHALLENGES; // API endpoint URL
        const method = "updateChallenge";
        const body = {
            "method": method,
            "game": game_id,
            "challenge_name": challenge_name,
            "data": data
        };

        const options = {
            method: 'PUT', // Use PUT or PATCH for updating resources
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
            credentials: 'include',
        };

        try {
            const response = await fetch(url, options);
            const json = await response.json(); // Parse JSON response
            if (!response.ok) {
                throw new Error(json.message || "Unknown error occurred");
            }
            return json;
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            throw error; // Re-throw error to be caught by caller
        }
    };

    // Function to process the image and extract RGB and BW values
    const processImage = (ctx, pixelArtSize) => {
        let rgb = [];
        let bw = [];
        for (let y = 0; y < pixelArtSize; y++) {
            for (let x = 0; x < pixelArtSize; x++) {
                const imageData = ctx.getImageData(x, y, 1, 1);
                const r = imageData.data[0];
                const g = imageData.data[1];
                const b = imageData.data[2];

                rgb.push([r, g, b]); // Store RGB values

                const sum = r + g + b;
                bw.push(sum < tolerance ? 1 : 0); // Convert to BW based on tolerance
            }
        }
        setRGBValues(rgb); // Update RGB values in context
        setBWValues(bw);   // Update BW values in context
    };

    // Function to convert the image to black and white
    const convertToBlackAndWhite = () => {
        if (!image) return;
        const canvas = canvasRef.current;
        const bwCanvas = bwCanvasRef.current;
        const bwCtx = bwCanvas.getContext('2d');
        bwCtx.imageSmoothingEnabled = false; // Disable smoothing for pixelated effect
        bwCtx.drawImage(canvas, 0, 0, displaySize, displaySize);

        const imageData = bwCtx.getImageData(0, 0, bwCanvas.width, bwCanvas.height);
        // Loop through each pixel and convert to BW
        for (let i = 0; i < imageData.data.length; i += 4) {
            const sum = imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2];
            const value = sum < tolerance ? 0 : 255;
            imageData.data[i] = value;       // Red channel
            imageData.data[i + 1] = value;   // Green channel
            imageData.data[i + 2] = value;   // Blue channel
        }
        bwCtx.putImageData(imageData, 0, 0);

        processImage(canvasRef.current.getContext('2d'), pixelArtSize); // Update pixel data
    };

    // Handler for file input change event
    const handleFileChange = (e) => {
        const file = e.target.files ? e.target.files[0] : null;
        setImageFile(file);      // Update state with the selected file
        handleImageChange(file); // Process the new image file
    };

    // Function to handle new image file
    const handleImageChange = (file) => {
        if (file) {
            if (pixelArtSize === 0) {
                setPixelArtSize(15); // Default size if not set
            }

            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = canvasRef.current;
                    canvas.width = pixelArtSize;
                    canvas.height = pixelArtSize;

                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, pixelArtSize, pixelArtSize); // Draw image on canvas

                    if (!isSameSize) {
                        processImage(ctx, pixelArtSize); // Process the image
                    }

                    // Update display canvas
                    const displayCanvas = displayCanvasRef.current;
                    displayCanvas.width = displaySize;
                    displayCanvas.height = displaySize;

                    const displayCtx = displayCanvas.getContext('2d');
                    displayCtx.imageSmoothingEnabled = false;
                    displayCtx.drawImage(canvas, 0, 0, displaySize, displaySize);

                    // Update BW canvas
                    const bwCanvas = bwCanvasRef.current;
                    bwCanvas.width = displaySize;
                    bwCanvas.height = displaySize;

                    const bwCtx = bwCanvas.getContext('2d');
                    bwCtx.imageSmoothingEnabled = false;
                    bwCtx.drawImage(canvas, 0, 0, displaySize, displaySize);

                    setImage(img); // Update image state
                };
                img.src = event.target.result; // Set image source
            };
            reader.readAsDataURL(file); // Read the file as data URL
            setTolerance(400);          // Default tolerance
        }
    };

    // Effect to convert image to BW when image or tolerance changes
    useEffect(() => {
        convertToBlackAndWhite();
    }, [image, tolerance]);

    useEffect(() => {
        if (initialNonogramData) {
            // Split the data string into an array of numbers
            const dataArray = initialNonogramData.split(',').map(Number);
            // Check if the array includes 255
            if (dataArray.includes(255)) {
                setShowDisplayCanvas(true);
            } else {
                setShowDisplayCanvas(false);
            }
        }
    }, [initialNonogramData]);

    useEffect(() => {
        setChallengeName(initialChallengeName || "");
    }, [initialChallengeName]);

    // Handler for posting the Nonogram
    async function handlePostNonogram () {
        if (!isValid || !image || challenge_name.trim() === "") {
            // Show error alert if any condition is not met
            showAlert(
                <span>Either <strong>game is not valid</strong>, <strong>there is no image</strong>, or <strong>there is no name</strong>!</span>, "error");
            return;
        }


            try {
                // Post monochrome challenge
                if(initialNonogramData) {
                    await updateChallenge(game_id, challenge_name, bwValues.toString());
                    await updateChallenge(game_id, challenge_name, rgbValues.toString());
                }
                await postChallenge(game_id, 1, challenge_name, "monochrome", bwValues.toString());
                // Post color challenge
                await postChallenge(game_id, 2, challenge_name, "color", rgbValues.toString());
                // Post status as available
                await postChallenge(game_id, 3, challenge_name, "status", "available");
                // Update new uploaded Nonogram in context
                setNewUploadedNono({ name: challenge_name, data: bwValues.toString(), date: null, gameid: game_id });
                // Show success alert
                showAlert(<span><strong>{challenge_name}</strong> successfully uploaded!</span>, "success");
                if (onUpdate) {
                    await handlePostNonogram();
                    onUpdate();
                }
            } catch (error) {
                // Show error alert if upload fails
                showAlert(
                    <span>
                    Error uploading
                    <strong>{challenge_name}</strong>. Make sure the name
                    <strong>{challenge_name}</strong> doesn't exist already!
                </span>,
                    "error"
                );
            }


    };

    // Function to toggle pixel color on BW canvas when clicked
    const togglePixelColor = (e) => {
        const bwCanvas = bwCanvasRef.current;
        const bwCtx = bwCanvas.getContext('2d');
        const rect = bwCanvas.getBoundingClientRect();
        // Calculate clicked position relative to canvas
        const toggleSizeX = Math.floor((e.clientX - rect.left) / (displaySize / pixelArtSize));
        const toggleSizeY = Math.floor((e.clientY - rect.top) / (displaySize / pixelArtSize));

        const index = toggleSizeY * pixelArtSize + toggleSizeX;

        // Loop to toggle pixel color
        for (let i = 0; i < displaySize / pixelArtSize; i++) {
            for (let j = 0; j < displaySize / pixelArtSize; j++) {
                const imageData = bwCtx.getImageData(toggleSizeX * (displaySize / pixelArtSize) + i, toggleSizeY * (displaySize / pixelArtSize) + j, 1, 1);
                const isBlack = imageData.data[0] === 0 && imageData.data[1] === 0 && imageData.data[2] === 0;
                const newColor = isBlack ? 255 : 0;
                imageData.data[0] = newColor; // Update red channel
                imageData.data[1] = newColor; // Update green channel
                imageData.data[2] = newColor; // Update blue channel
                bwCtx.putImageData(imageData, toggleSizeX * (displaySize / pixelArtSize) + i, toggleSizeY * (displaySize / pixelArtSize) + j);
            }
        }

        // Update BW values array
        const newBWValues = [...bwValues];
        newBWValues[index] = newBWValues[index] === 1 ? 0 : 1;
        setBWValues(newBWValues);
    };

    // Effect to set pixel art size to image size if isSameSize is true
    useEffect(() => {
        if (!image) return;
        if (image.width < 3 || image.width > 15) return;

        if (isSameSize) {
            setPixelArtSize(image.width);
        }
    }, [isSameSize]);

    // Effect to handle image changes when file or size changes
    useEffect(() => {
        if (imageFile) {
            handleImageChange(imageFile);
        }
    }, [imageFile, pixelArtSize]);

    // Effect to add click listener to BW canvas
    useEffect(() => {
        const bwCanvas = bwCanvasRef.current;
        bwCanvas.addEventListener('click', togglePixelColor);
        return () => {
            bwCanvas.removeEventListener('click', togglePixelColor);
        };
    }, [togglePixelColor]);

    // Process the image when `image` changes
    useEffect(() => {
        if (image) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            canvas.width = pixelArtSize;
            canvas.height = pixelArtSize;
            ctx.drawImage(image, 0, 0, pixelArtSize, pixelArtSize);

            // Update display and BW canvases
            updateDisplayCanvases();
            processImage(ctx, pixelArtSize);
        }
    }, [image]);

// Function to update display and BW canvases
    const updateDisplayCanvases = () => {
        // Update display canvas
        if(displayCanvasRef.current){
            const displayCanvas = displayCanvasRef.current;
            displayCanvas.width = displaySize;
            displayCanvas.height = displaySize;
            const displayCtx = displayCanvas.getContext('2d');
            displayCtx.imageSmoothingEnabled = false;
            displayCtx.drawImage(canvasRef.current, 0, 0, displaySize, displaySize);


        }
        // Update BW canvas
        const bwCanvas = bwCanvasRef.current;
        bwCanvas.width = displaySize;
        bwCanvas.height = displaySize;
        const bwCtx = bwCanvas.getContext('2d');
        bwCtx.imageSmoothingEnabled = false;
        bwCtx.drawImage(canvasRef.current, 0, 0, displaySize, displaySize);
    };


    // Handler for pixel size button clicks
    const handleButtonClick = (size) => {
        setPixelArtSize(size); // Update pixel art size
        setActiveButton(size); // Set active button
    };

    return (
        <div style={{ alignContent: 'center' }}>
            {/* File input for uploading image */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Input type="file" accept="image/png" onChange={handleFileChange} />
            </div>
            {/* Buttons to select pixel art size */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <label>Pixel Art Size: </label>
                <button
                    onClick={() => handleButtonClick("5")}
                    style={{
                        backgroundColor: activeButton === "5" ? 'var(--main-hover)' : 'var(--main)',
                        color: 'var(--light)'
                    }}
                >5</button>
                <button
                    onClick={() => handleButtonClick("10")}
                    style={{
                        backgroundColor: activeButton === "10" ? 'var(--main-hover)' : 'var(--main)',
                        color: 'var(--light)'
                    }}
                >10</button>
                <button
                    onClick={() => handleButtonClick("15")}
                    style={{
                        backgroundColor: activeButton === "15" ? 'var(--main-hover)' : 'var(--main)',
                        color: 'var(--light)'
                    }}
                >15</button>
            </div>
            {/* Checkbox to use image's original size */}
            <label style={{ display: "flex", justifyContent: "center" }}>
                <label>
                    <Checkbox checked={isSameSize}
                              className="custom-checkbox"
                              onChange={(e) => setIsSameSize(e.target.checked)}
                              sx={{ margin: '0' }} />
                    Same as image size
                </label>
            </label>
            {/* Canvas elements for displaying and processing image */}
            <div className={"canvas-display"}>
                <canvas ref={canvasRef} style={{display: 'none'}}/>
                {/* Hidden processing canvas */}
                {showDisplayCanvas && (
                    <canvas ref={displayCanvasRef} />
                    )}
                {/* Display canvas */}
                <canvas ref={bwCanvasRef}/>
                {/* Black and white canvas */}

            </div>
            {/* Slider to adjust tolerance */}
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '2vh', padding: '0 5rem'}}>
                <Slider
                    value={tolerance}
                    onChange={(event, newValue) => {
                        setTolerance(newValue);
                    }}
                    aria-labelledby="input-slider"
                    min={0}
                    max={765}
                    valueLabelDisplay="auto"
                    style={{ backgroundColor: 'var(--light)', color: 'var(--main)' }}
                />
            </div>
            {/* Input for challenge name and button to post Nonogram */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '10px' }}>
                <input type="text" style={{ maxWidth: '20rem' }} value={challenge_name}
                       onChange={(e) => setChallengeName(e.target.value)}
                       placeholder="Enter nonogram name" />
                <button onClick={handlePostNonogram}
                        style={{ color: "var(--light)", backgroundColor: isValid ? "var(--main)" : 'red' }}>Post Nonogram
                </button>
            </div>
            <br />
        </div>
    );
}

export default NonogramGenerator;
