// Helper function to convert dictionary to CSV format
function convertDictToCSV(objArray) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = `${Object.keys(array[0]).join(',')}\r\n`;

    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (const index in array[i]) {
            if (line !== '') line += ',';

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

function reformatData(data) {
    const result = [];
    // Assuming all arrays are the same length; using 'date' array length as reference
    for (let i = 0; i < data.date.length; i++) {
        let row = {};
        for (const key in data) {
            row[key] = data[key][i]; // Assign each property to the corresponding entry in each array
        }
        result.push(row);
    }
    return result;
}

// React component
const DownloadCSVButton = ({ data, fileName }) => {
    // Convert data to CSV


    // Function to handle the download
    const handleDownload = () => {
        const formattedData = reformatData(data); // Ensure 'data' is the object containing your arrays
        const csv = convertDictToCSV(formattedData);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    // Render button
    return <button className={"Button"} onClick={handleDownload}>Download CSV</button>;
};

export default DownloadCSVButton;